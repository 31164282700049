import React, { useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap"
import { useLazyQuery } from "@apollo/client"
import {
  HANDLE_INSTAGRAM_LOGIN,
  HANDLE_TWITTER_LOGIN,
} from "../../../graphql/queries"

const AccountModal = ({ showModal, editMode, handleClose }) => {
  useEffect(() => {}, [])

  const linkedinOrganizationURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_LINKEDIN_REDIRECT_URL}?accountType=linkedinCompany&state=04830o43r4fr3fd3&scope=r_basicprofile%20r_emailaddress%20w_member_social%20w_organization_social%20r_organization_social%20rw_organization_admin`

  const linkedinPersonalURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_LINKEDIN_REDIRECT_URL}?accountType=linkedinPersonal&state=04830o43r4fr3fd3&scope=r_basicprofile%20r_emailaddress%20w_member_social`

  const facebookURl = `https://www.facebook.com/v15.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FACEBOOK_REDIRECT_URL}?accountType=facebookPages&scope=pages_manage_posts`

  const instagramURL = `https://www.facebook.com/v15.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FACEBOOK_REDIRECT_URL}?accountType=instagram&scope=instagram_basic,instagram_content_publish`

  const accounts = [
    {
      id: "linkedin",
      name: "LinkedIn",
      authURL: linkedinPersonalURL,
      iconClass: "fab fa-linkedin-in",
    },
    {
      id: "linkedin-company",
      name: "LinkedIn Company",
      authURL: linkedinOrganizationURL,
      iconClass: "fab fa-linkedin-in",
    },
    {
      id: "twitter",
      name: "Twitter",
      authURL: "",
      iconClass: "fab fa-twitter",
    },
    {
      id: "facebook-pages",
      name: "Facebook Pages",
      authURL: facebookURl,
      iconClass: "fab fa-facebook-f",
    },
    {
      id: "instagram",
      name: "Instagram",
      authURL: instagramURL,
      iconClass: "fab fa-instagram",
    },
  ]

  const [handleTwitterLogin] = useLazyQuery(HANDLE_TWITTER_LOGIN, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.handleTwitterLogin) {
        let oAuthData = data.handleTwitterLogin
        if (oAuthData.oAuthToken && oAuthData.oAuthTokenSecret) {
          let element = document.createElement("a")
          let url = `https://twitter.com/oauth/authorize?oauth_token=${oAuthData.oAuthToken}&oauth_token_secret=${oAuthData.oAuthTokenSecret}&oauth_callback_confirmed=true`
          element.href = url
          element.click()
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [handleInstagramLogin] = useLazyQuery(HANDLE_INSTAGRAM_LOGIN, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      console.log("data", data)
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const getCorrespondingUI = account => {
    if (account.id === "twitter") {
      return (
        <div onClick={handleTwitterLogin} className="account-box">
          <i className={account.iconClass} style={{ fontSize: "1.8rem" }}></i>
        </div>
      )
    } else {
      return (
        <a href={account.authURL} className="account-box">
          <i className={account.iconClass} style={{ fontSize: "1.8rem" }}></i>
        </a>
      )
    }
  }

  return (
    <Modal size="lg" isOpen={showModal} backdrop="static" centered={true}>
      <ModalHeader toggle={handleClose}>
        <div>Create Account</div>
        <small className="font-size-11 text-secondary">
          Choose a social account you would like to add an account for.
        </small>
      </ModalHeader>
      <ModalBody>
        <div className="search-box chat-search-box py-4">
          <div className="position-relative">
            <Input
              type="text"
              className="form-control accounts-search"
              placeholder="Search..."
            />
            <i className="bx bx-search-alt search-icon" />
          </div>
        </div>

        <div className="mt-2 my-2 mx-3 ">
          <div
            className="d-flex justify-content-around align-items-center"
            style={{
              overflowX: "auto",
              overflowY: "hidden",
            }}
          >
            {accounts.map(account => {
              return (
                <div
                  key={account.id}
                  className="d-flex flex-column justify-content-center align-items-center text-center me-4"
                >
                  <div>{getCorrespondingUI(account)}</div>
                  <div className="mt-2 font-size-12 font-weight-semibold">
                    {account.name}
                  </div>
                </div>
              )
            })}
          </div>

          {/* <div onClick={handleTwitterLogin} className="account-box me-4">
            <img
              width="48"
              src="https://img.icons8.com/color/48/000000/twitter.png"
            />
          </div>
          <a href={facebookURl} className="account-box">
            <img
              width="48"
              src="https://img.icons8.com/color/48/000000/facebook.png"
            />
          </a> */}
        </div>
      </ModalBody>
      <ModalFooter>
        <div
          className="btn btn-outline-secondary rounded-3"
          onClick={handleClose}
        >
          Cancel
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default AccountModal
