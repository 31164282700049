import React, { useState, useEffect } from "react"
import { Row, Col, Form, Input, Select, Divider, Collapse } from "antd"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import IconButton from "@material-ui/core/IconButton"
import toast, { Toaster } from "react-hot-toast"
import { ColorPicker } from "material-ui-color"
import GroupCustomField from "./groupCustomField"
const { Panel } = Collapse
const { TextArea } = Input

const TaskCustomFieldModal = ({
  showModal,
  handleCloseModal,
  defaultCustomFields,
  column,
  spaceID,
  onSave,
  onEdit,
  onDelete,
  editMode,
  data,
}) => {
  const [dropdown, setDropdown] = useState(undefined)
  const [fieldName, setFieldName] = useState("")
  const [fieldType, setFieldType] = useState("")
  const [fields, setFields] = useState([
    {
      fieldType: "",
      fieldName: "",
      description: "",
    },
  ])

  const palette = {
    red: "#EF6D6D",
    blue: "#398AB9",
    green: "#8BDB81",
    yellow: "#F1D00A",
    pink: "#FF7BA9",
    grey: "#789395",
    greenSheen: "#70C1B3",
    ming: "#3C6E71",
  }

  useEffect(() => {
    if (showModal && !editMode && column) {
      setFieldType(column.name)
      setFieldName("")
      setDropdown({
        show:
          (column && column.name === "Dropdown") ||
          column.name === "Dropdown Multi"
            ? true
            : false,
        options: [{ name: "", placeholder: "Option 1", color: "#6FB2D2" }],
      })
      setFields(null)
    } else if (showModal && editMode) {
      console.log("data", data)
      setFieldName(data.fieldName)
      setFieldType(data.fieldType)
      setDropdown({
        show: fieldType.includes("Dropdown"),
        options: data.options,
      })
      setFields(data.fields)
    }
  }, [showModal])

  const handleFieldTypeChange = value => {
    if (value === "Dropdown" || value === "Dropdown Multi") {
      setDropdown({
        show: true,
        options: dropdown.options,
      })
    } else {
      setDropdown({
        show: false,
        options: dropdown.options,
      })
    }
    setFieldType(value)
  }

  const handleGroupFieldTypeChange = values => {
    console.log("values", values)
  }

  const handleAddDropdownOption = () => {
    let l = dropdown.options.length
    if (dropdown.options[l - 1].name === "") {
      return toast.error("Please enter option name to add more options", {
        position: "top-right",
      })
    }
    const newOptions = [
      ...dropdown.options,
      {
        name: "",
        placeholder: `Option ${dropdown.options.length + 1}`,
        color: "#6FB2D2",
      },
    ]
    setDropdown({
      show: true,
      options: newOptions,
    })
  }

  const handleDropdownOptionChange = (e, index) => {
    let value = e.target.value
    let newOptions = [...dropdown.options]
    newOptions[index] = {
      name: value,
      color: newOptions[index].color,
    }

    setDropdown({
      show: dropdown.show,
      options: newOptions,
    })
  }

  const handleDropdownOptionRemove = index => {
    if (dropdown.options.length > 1) {
      let newOptions = dropdown.options.filter((_, i) => i !== index)

      let newState = {
        show: true,
        options: newOptions,
      }

      setDropdown(newState)
    } else {
      toast.error("A dropdown must have atlease one option.", {
        position: "top-right",
      })
    }
  }

  const handleColorPicker = (e, index) => {
    let newOptions = []

    dropdown.options.forEach(ele => {
      newOptions.push({
        name: ele.name,
        color: ele.color,
      })
    })

    let obj = {
      ...newOptions[index],
      color: `#${e.hex}`,
    }
    newOptions[index] = obj

    setDropdown({
      show: dropdown.show,
      options: newOptions,
    })
  }

  const handleColorPickerChange = (field, index) => {
    fields[index] = field
    setFields([...fields])
  }

  // Handle submit
  const handleSubmit = values => {
    let input = {
      spaceID: spaceID,
      fieldName: values.fieldName,
      fieldType: fieldType,
      description: values.description,
    }

    if (fieldType.includes("Dropdown")) {
      let options = []
      if (dropdown && dropdown.options) {
        for (let i = 0; i < dropdown.options.length; i++) {
          let option = dropdown.options[i]
          if (option.name === "") {
            toast.error("All options must have a name.", {
              position: "top-right",
            })
            return
          } else {
            options.push({
              name: option.name,
              color: option.color,
            })
          }
        }
      }
      input["options"] = options
    }

    if (fieldType === "Group") {
      input.fields = fields
      fields.forEach((ele, index) => {
        delete ele.show
        console.log("ele", ele)
        if (ele.fieldType.includes("Dropdown")) {
          let options = []
          if (ele && ele.options) {
            for (let i = 0; i < ele.options.length; i++) {
              let option = ele.options[i]
              if (option.name === "") {
                toast.error("All options must have a name.", {
                  position: "top-right",
                })
                return
              } else {
                options.push({
                  name: option.name,
                  color: option.color,
                })
              }
            }
          }
          input.fields[index].options = options
        } else {
          delete input.fields[index].options
        }
      })
    }
    if (input.fields && input.fields.length) {
      input.fields.forEach(ele => {
        if (ele.__typename) {
          delete ele.__typename
        }
      })
    }
    console.log("input", input)
    if (spaceID && spaceID !== null) {
      if (!editMode) {
        onSave(input)
      } else if (editMode && data) {
        let newInput = {
          spaceID: input.spaceID,
          fieldName: input.fieldName,
          fieldType: input.fieldType,
          description: input.description,
        }
        if (input.fields) {
          let fields = []
          input.fields.forEach(ele => {
            let obj = {}
            if (ele.id) {
              obj = {
                fid: ele.id,
                fieldName: ele.fieldName,
                fieldType: ele.fieldType,
                description: ele.description,
              }
            } else {
              obj = {
                fieldName: ele.fieldName,
                fieldType: ele.fieldType,
                description: ele.description,
              }
            }

            if (ele.options) {
              obj.options = []
              ele.options.forEach(option => {
                obj.options.push({
                  name: option.name,
                  color: option.color,
                })
              })
            }
            fields.push(obj)
          })
          newInput["fields"] = fields
        }
        console.log("newInput", newInput)
        onEdit(data.id, newInput)
      }
    }
  }

  const handleDelete = () => {
    if (spaceID) {
      onDelete(spaceID)
    }
  }

  const handleFieldName = (txt, index) => {
    fields[index].fieldName = txt
    setFields([...fields])
  }
  const handleFieldType = (value, index) => {
    fields[index].fieldType = value
    console.log("value")
    if (value === "Dropdown" || value === "Dropdown Multi") {
      fields[index].show = true
      fields[index].options = [
        { name: "", placeholder: "Option 1", color: "#6FB2D2" },
      ]
    } else {
      fields[index].show = false
      fields[index].options = [
        { name: "", placeholder: "Option 1", color: "#6FB2D2" },
      ]
    }
    console.log("fields", fields)
    setFields([...fields])
  }

  const handleAddField = () => {
    let newField = {
      fieldName: "",
      fieldType: "",
    }
    console.log("newField", newField)
    if (fields) {
      setFields([...fields, newField])
    } else {
      setFields([newField])
    }
  }

  const handleDeleteField = index => {
    if (index > -1) {
      fields.splice(index, 1)
      setFields([...fields])
    }
  }

  return (
    <Modal
      isOpen={showModal}
      centered={true}
      toggle={handleCloseModal}
      backdrop="static"
      size="md"
      scrollable
    >
      <ModalHeader tag="h4" toggle={handleCloseModal}>
        {editMode ? "Edit Column" : "Add column"}
      </ModalHeader>

      <ModalBody>
        <Form
          id="task-customfield-form"
          onFinish={handleSubmit}
          initialValues={{
            ["fieldName"]: editMode ? fieldName : "",
            ["fieldType"]: editMode ? fieldType : column?.name,
            ["description"]: editMode ? data?.description : "",
          }}
        >
          {/* Field Name */}
          <Row gutter={26}>
            <Col span={12}>
              <span className="d-block text-secondary font-size-12 mb-1">
                Field name <span className="text-danger">*</span>
              </span>
              <Form.Item
                name="fieldName"
                rules={[
                  {
                    required: true,
                    message: "Please input your field name!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter field name"
                  className="rounded-3"
                  size="middle"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <span className="d-block text-secondary font-size-12 mb-1">
                Field type <span className="text-danger">*</span>
              </span>
              <Form.Item
                name="fieldType"
                rules={[
                  {
                    required: true,
                    message: "Please input your field name!",
                  },
                ]}
              >
                <Select
                  disabled={editMode}
                  suffixIcon={<i className="fas fa-chevron-down"></i>}
                  className="subtask-hover rounded-3"
                  style={{
                    height: "32px",
                    border: "1px solid #d9d9d9",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  allowClear
                  onChange={handleFieldTypeChange}
                  value={fieldType ? fieldType : ""}
                  placeholder="Select a field type"
                  bordered={false}
                  dropdownStyle={{ borderRadius: "6px" }}
                >
                  {defaultCustomFields.map((data, index) => (
                    <Select.Option value={data.name} key={index}>
                      {data.name}
                    </Select.Option>
                  ))}
                </Select>
                <small className="text-secondary font-size-10">
                  Once you choose field type, you cannot change it.
                </small>
              </Form.Item>
            </Col>
          </Row>
          {/* {fieldType && fieldType === "Group" ? (
            <Row>
              <Divider />
              <Col span={24}>
                <span className="d-block text-secondary font-size-12 mb-1">
                  Fields
                </span>
                <Form.Item
                  name="fieldTypes"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    disabled={editMode}
                    suffixIcon={<i className="fas fa-chevron-down"></i>}
                    className="subtask-hover rounded-3"
                    mode="multiple"
                    allowClear
                    style={{
                      minHeight: "32px",
                      border: "1px solid #d9d9d9",
                      borderRadius: "4px",
                      width: "100%",
                    }}
                    onChange={handleGroupFieldTypeChange}
                    value={fieldTypes ? fieldTypes : []}
                    placeholder="Select a field type"
                    bordered={false}
                    dropdownStyle={{ borderRadius: "6px" }}
                  >
                    {defaultCustomFields.map((data, index) => {
                      if (data.name === "Group") {
                        return
                      }
                      return (
                        <Select.Option value={data.name} key={index}>
                          {data.name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Divider />
            </Row>
          ) : null} */}
          {/* Descriptoin */}
          <Row>
            <Col span={24}>
              <span className="d-block text-secondary font-size-12 mb-1">
                Description
              </span>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <TextArea
                  className="mb-2"
                  style={{
                    borderRadius: "8px",
                  }}
                  placeholder="Add description to this custom field"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* dropdownOptions */}
          {dropdown && dropdown.show
            ? dropdown.options.map((option, index) => {
                return (
                  <div className="d-flex" key={index}>
                    <div
                      className="subtask-hover"
                      style={{
                        width: "90%",
                      }}
                    >
                      <Row className="customMargin pt-1 ps-1">
                        <Col span={24}>
                          <Input
                            value={option.name}
                            bordered={false}
                            onChange={e => handleDropdownOptionChange(e, index)}
                            size="middle"
                            placeholder={option.placeholder}
                            style={{
                              backgroundColor: "transparent",
                            }}
                            suffix={
                              <IconButton
                                disabled={
                                  editMode && index < data?.options?.length
                                }
                                onClick={() =>
                                  handleDropdownOptionRemove(index)
                                }
                                size="small"
                              >
                                <i className="fal fa-times font-size-14"></i>
                              </IconButton>
                            }
                          ></Input>
                        </Col>
                      </Row>
                      <div className="custom-border mt-1"></div>
                    </div>
                    <ColorPicker
                      onChange={e => {
                        handleColorPicker(e, index)
                      }}
                      palette={palette}
                      value={option.color}
                      hideTextfield
                      key={index}
                    />
                  </div>
                )
              })
            : ""}
          {dropdown && dropdown.show ? (
            <Row className="mt-2 ms-2">
              <Col>
                <div
                  onClick={handleAddDropdownOption}
                  className="customBtn p-1 px-2"
                >
                  <i className="fal fa-plus me-2"></i>
                  <span className="pe-1 font-size-12">Add an option</span>
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}

          {fieldType && fieldType === "Group" ? (
            <div>
              {/* <Divider /> */}
              <Collapse
                defaultActiveKey={[0]}
                ghost
                expandIcon={({ isActive }) => (
                  <i
                    className={`fas fa-caret-right ms-1 font-size-14 ${
                      isActive ? "bx-rotate-90" : ""
                    }`}
                  />
                )}
              >
                {fields &&
                  fields.map((field, index) => {
                    return (
                      <Panel
                        header={
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <div
                              className="font-size-12 font-weight-semibold"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              {field.fieldName === ""
                                ? `Field ${index + 1}`
                                : field.fieldName}
                            </div>
                          </div>
                        }
                        extra={
                          editMode === true && field.id !== undefined ? null : (
                            <div
                              onClick={event => {
                                event.stopPropagation()
                                handleDeleteField(index)
                              }}
                            >
                              <i className="fal fa-times subtask-hover p-1 px-2 rounded-3"></i>
                            </div>
                          )
                        }
                        key={index}
                        className="site-collapse-custom-panel my-2 w-100"
                      >
                        <GroupCustomField
                          field={field}
                          fieldIndex={index}
                          handleFieldName={handleFieldName}
                          handleFieldType={handleFieldType}
                          defaultCustomFields={defaultCustomFields}
                          palette={palette}
                          handleColorPickerChange={handleColorPickerChange}
                          editMode={editMode}
                        />
                      </Panel>
                    )
                  })}

                <div
                  className="btn btn-primary btn-sm"
                  onClick={handleAddField}
                >
                  Add field
                </div>
              </Collapse>
            </div>
          ) : null}
        </Form>
      </ModalBody>

      <ModalFooter>
        <div
          onClick={handleCloseModal}
          className="btn btn-outline-secondary rounded-3"
        >
          Cancel
        </div>
        <div
          onClick={handleDelete}
          hidden={!editMode}
          className="btn btn-danger rounded-3 d-none"
        >
          Delete
        </div>
        <button
          form="task-customfield-form"
          type="submit"
          className="btn btn-success rounded-3"
        >
          {editMode ? "Update" : "Save"}
        </button>
      </ModalFooter>

      {/* <Toaster /> */}
    </Modal>
  )
}

export default TaskCustomFieldModal
