import { gql } from "@apollo/client"

export const TASK_FRAGMENT = gql`
  fragment TaskFragment on Task {
    id
    title
    isSubtask
    parentID
    description
    status
    startDate
    endDate
    assignees {
      id
      user {
        id
        fName
        lName
        isDeleted
        profileURL
      }
    }
    subTasks {
      id
      parentID
      title
      startDate
      endDate
      status
      description
      comments {
        id
        isUserComment
        user {
          fName
          lName
          email
          profileURL
        }
        to {
          id
        }
        comment
        createdAt
        updatedAt
      }
    }
    comments {
      id
      isUserComment
      user {
        fName
        lName
        email
        profileURL
      }
      to {
        id
      }
      comment
      createdAt
      updatedAt
    }
    audit {
      id
      auditType
      timeStamp
      user {
        id
        fName
        lName
        email
        profileURL
      }
      customText
      from
      to
    }
    customFieldValues {
      custFieldID
      value
    }
  }
`

export const SUBTASK_FRAGMENT = gql`
  fragment SubtaskFragment on Task {
    id
    title
    parentID
    description
    status
    startDate
    endDate
    assignees {
      id
      user {
        id
        fName
        lName
        isDeleted
        firebaseID
      }
    }
    subTasks {
      id
      parentID
      title
      startDate
      endDate
      status
      description
      comments {
        id
        isUserComment
        user {
          fName
          lName
          email
        }
        comment
        createdAt
        updatedAt
      }
    }
    comments {
      id
      isUserComment
      user {
        fName
        lName
        email
      }
      comment
      createdAt
      updatedAt
    }
    audit {
      id
      auditType
      timeStamp
      taskID
      user {
        id
        fName
        lName
        email
      }
      from
      to
    }
    customFieldValues {
      custFieldID
      value
    }
  }
`

export const CUSTOM_FIELD_FRAGMENT = gql`
  fragment CustomFieldFragment on CustomField {
    id
    fieldName
    fieldType
    description
    options {
      name
      color
    }
    fromSettings
    visibility
    helperText
    isAllSpaces
    isAllWorkspaces
    workspaceIDs
    spaceIDs
    space {
      id
      name
    }
    workspace {
      id
      name
    }
    workspaces {
      name
    }
    spaces {
      name
    }
    active
  }
`

export const USER = gql`
  query {
    user {
      id
      fName
      lName
      email
      mobile
      role
      tenant {
        Name
      }
      profileURL
    }
  }
`

export const WORKSPACES = gql`
  query {
    workspaces {
      id
      name
      tenant {
        id
        Name
      }
    }
  }
`

export const TASKS = gql`
  ${TASK_FRAGMENT}
  query ($spaceID: String!) {
    tasks(spaceID: $spaceID) {
      ...TaskFragment
    }
  }
`
export const TASKS_PAGINATED = gql`
  ${TASK_FRAGMENT}
  query ($spaceID: String!, $after: String) {
    tasksPaginated(spaceID: $spaceID, after: $after) {
      tasks {
        ...TaskFragment
      }
      after
      filter {
        id
        where
        filters {
          type
          condition
          options
        }
      }
      totalLength
    }
  }
`

export const GET_TASK = gql`
  ${TASK_FRAGMENT}
  query Task($id: String!) {
    getTask(id: $id) {
      ...TaskFragment
    }
  }
`

export const GET_SUB_TASK = gql`
  ${SUBTASK_FRAGMENT}
  query SubTask($id: String!) {
    getSubTask(id: $id) {
      ...SubtaskFragment
    }
  }
`

export const GET_SPACE = gql`
  query ($id: String!) {
    getSpace(id: $id) {
      name
    }
  }
`

export const SPACES_FRAGMENT = gql`
  fragment SpacesFragment on Space {
    id
    name
    workspace {
      id
    }
    isFolder
  }
`

export const SPACES = gql`
  ${SPACES_FRAGMENT}
  query ($workspaceID: String!) {
    spaces(workspaceID: $workspaceID) {
      ...SpacesFragment
      tenant {
        id
        Name
      }
      spaces {
        ...SpacesFragment
        spaces {
          ...SpacesFragment
          spaces {
            ...SpacesFragment
          }
        }
      }
    }
  }
`

// Sub user
export const SUB_USERS = gql`
  query {
    subUsers {
      id
      fName
      lName
      email
      role
      designation
      department
      mobile
      employeeID
      userGroup
      lastLoggedIn
      isActive
      workspaces {
        id
        name
      }
      allocationProfiles {
        id
        name
      }
    }
  }
`

export const GET_CUSTOME_ROLES = gql`
  query {
    customRoles {
      id
      name
    }
  }
`
export const GET_USER_BY_REFRESH_TOKEN = gql`
  query ($refreshToken: String!) {
    getUser(refreshToken: $refreshToken) {
      id
      fName
      lName
      mobile
      email
      role
    }
  }
`

export const GET_ALL_USERS_BY_TENANT_ID = gql`
  query {
    getUsers {
      id
      fName
      lName
      firebaseID
      isDeleted
      profileURL
    }
  }
`
export const USERS_ACCESS_BY_TENANT_ID = gql`
  query userTaskAccess($workspaceID: String!, $spaceID: String!) {
    userTaskAccess(workspaceID: $workspaceID, spaceID: $spaceID)
  }
`

export const GET_TASKS_BY_USER_ID = gql`
  ${TASK_FRAGMENT}
  query ($workspaceID: String!) {
    getTasksByUserID(workspaceID: $workspaceID) {
      ...TaskFragment
      space {
        id
      }
    }
  }
`

// Allocation Profile

export const ALLOCATION_PROFILES = gql`
  query {
    allocationProfiles {
      id
      name
      workspace {
        id
        name
      }
      spaces {
        id
        name
      }
    }
  }
`

export const GET_LAST_WORKSPACE = gql`
  query {
    getLastWorkspace {
      id
      name
    }
  }
`

// CUSTOM FIELDS

export const GET_CUSTOM_FIELDS_BY_SPACE_ID = gql`
  query ($spaceID: String!) {
    getCustomFields(spaceID: $spaceID) {
      id
      fieldName
      fieldType
      description
      fields {
        id
        fieldName
        fieldType
        description
        options {
          name
          color
        }
      }
      options {
        name
        color
      }
    }
  }
`
export const GET_CUSTOM_FIELDS_BY_TENANT_ID = gql`
  query {
    getCustomFieldsByTenantID {
      id
      fieldName
      fieldType
      description
      options {
        name
        color
      }
    }
  }
`

export const GET_CUSTOM_FIELDS_BY_WORKSPACE_ID = gql`
  query ($workspaceID: String!) {
    getCustomFieldsByWorkspaceID(workspaceID: $workspaceID) {
      id
      fieldName
      spaceName
      fromSettings
      fieldType
      description
      options {
        name
        color
      }
    }
  }
`

// Task Orders

export const TASK_ORDERS = gql`
  query ($spaceID: String!) {
    taskOrders(spaceID: $spaceID) {
      columnOrderIDs
    }
  }
`

export const GET_COLUMN_ORDER = gql`
  query ($spaceID: String!) {
    getColumnOrders(spaceID: $spaceID)
  }
`

export const GET_AUDIT_NOMENCLATURE = gql`
  query {
    getAuditNomenclature {
      id
      field
      type
      audit
    }
  }
`
export const GET_AUDIT_FOR_CURRENT_TASK = gql`
  query ($taskID: String!) {
    getAudit(taskID: $taskID) {
      id
      auditType
      taskID
      createdBy
      timeStamp
      to
      from
      user {
        fName
      }
    }
  }
`

// TIME TRACK

export const GET_ALL_TIME_TRACK = gql`
  query ($weekRange: String!) {
    getAllTimeTrack(weekRange: $weekRange) {
      id
      workspace
      type
      billable
      weekRange
      weekDates
      notes
    }
  }
`

// DASHBOARD
export const GET_DASHBOARDS = gql`
  query ($workspaceID: String!) {
    getDashboards(workspaceID: $workspaceID) {
      id
      name
      expiryDate
      startDate
      endDate
    }
  }
`

export const GET_TASK_DETAIL = gql`
  query ($input: TaskDetailInput!) {
    getTaskDetail(input: $input) {
      fieldID
      index
      name
      count
      color
    }
  }
`

export const GET_TASK_DETAILS = gql`
  query ($fieldID: String!, $workspaceID: String!) {
    getTaskDetails(fieldID: $fieldID, workspaceID: $workspaceID) {
      fieldID
      index
      name
      count
      color
    }
  }
`

export const GET_DASHBOARD = gql`
  query ($id: String!) {
    getDashboard(id: $id) {
      id
      name
      layout
      externalLink
      expiryDate
      startDate
      endDate
    }
  }
`

export const GET_EXTERNAL_DASHBOARD = gql`
  query ($dashboardID: String!) {
    getExternalDashboard(dashboardID: $dashboardID) {
      id
      dashboardID
      link
      expiryDate
      widgets
    }
  }
`

export const GET_LAYOUT = gql`
  query ($id: String!) {
    getLayout(id: $id) {
      layout
    }
  }
`

export const GET_WIDGETS = gql`
  query ($dashboardID: String!, $workspaceID: String!) {
    getWidgets(dashboardID: $dashboardID, workspaceID: $workspaceID) {
      id
      widgetName
      dataSource
      visualName
      text
      datas {
        status
        count
      }
      externalDatas {
        status
        count
      }
      createdAt
    }
  }
`

export const GET_EXT_WIDGETS = gql`
  query ($dashboardID: String!, $workspaceID: String!) {
    getExternalWidgets(dashboardID: $dashboardID, workspaceID: $workspaceID) {
      id
      widgetName
      dataSource
      visualName
      text
      datas {
        fieldID
        index
        name
        count
        color
      }
    }
  }
`

export const FILTER_WIDGET_DATAS_BY_DATE = gql`
  query ($input: FilterInput!) {
    filterWidgetsDataByDate(input: $input) {
      id
      widgetName
      dataSource
      visualName
      text
      fields {
        id
        fieldName
        spaceName
        options {
          name
        }
      }
      filters {
        fieldID
        type
        values
      }
      datas {
        fieldID
        index
        name
        count
        color
      }
      tableData {
        fieldID
        fieldName
        spaceName
        values {
          taskID
          data
        }
      }
      createdAt
      updatedAt
    }
  }
`

export const GET_WIDGET_TASKS = gql`
  query ($fieldID: String!, $index: String!) {
    getWidgetTasks(fieldID: $fieldID, index: $index) {
      id
      title
      isSubtask
      parentID
      description
      status
      startDate
      endDate
    }
  }
`

export const GET_TASK_COLUMNS = gql`
  query ($workspaceID: String!) {
    getTaskColumns(workspaceID: $workspaceID) {
      id
      fieldName
      fieldType
      description
    }
  }
`

// NOTE Social accounts API
export const ACCOUNTS = gql`
  query {
    accounts {
      id
      pages {
        name
        img
      }
      accountType
      firstName
      lastName
      isActive
      expiryDate
      profilePicURL
      description
      following
      user {
        fName
        lName
      }
      type
    }
  }
`
export const POSTS = gql`
  query {
    posts {
      id
      start
      isPosted
      isTextPost
      isPicturePost
      isVideoPost
      scheduleDate
      accounts {
        id
        accountType
      }
      postResponses {
        accountID
        type
        postPages {
          postID
          postURL
          pageName
        }
      }
      user {
        fName
        lName
      }
      subject
      content
      createdAt
      updatedAt
      awsMedias {
        id
        name
        preview
        type
      }
      campaign {
        id
        name
      }
    }
  }
`

export const HANDLE_TWITTER_LOGIN = gql`
  query {
    handleTwitterLogin {
      oAuthToken
      oAuthTokenSecret
    }
  }
`

export const HANDLE_INSTAGRAM_LOGIN = gql`
  query {
    handleInstagramLogin
  }
`

// NOTE CAMPAIGNS

export const CAMPAIGNS = gql`
  query {
    campaigns {
      id
      name
      description
      startDate
      endDate
      isEvergreen
      status
      isAllWorkspace
      workspaces {
        id
      }
      user {
        id
      }
    }
  }
`

export const WORKSPACE_CAMPAIGNS = gql`
  query ($workspaceID: String!) {
    workspaceCampaigns(workspaceID: $workspaceID) {
      id
      name
      description
      startDate
      endDate
      isEvergreen
      status
      isAllWorkspace
      workspaces {
        id
      }
      user {
        id
      }
    }
  }
`

// Notifications
export const NOTIFICATIONS = gql`
  query {
    getAllNotifications {
      id
      from {
        id
        fName
        lName
        profileURL
      }
      to {
        id
        fName
        lName
        profileURL
      }
      type {
        id
        taskID
        commentID
        title
        spaceID
        workspaceID
      }
      timeStamp
      isRead
    }
  }
`

// Settings
export const SETTINGS = gql`
  query {
    settings {
      id
      notificationSound
      notificationPopup
    }
  }
`

// Task filter
export const GET_TASK_FILTER = gql`
  query ($spaceID: String!, $workspaceID: String!) {
    getTaskFilter(spaceID: $spaceID, workspaceID: $workspaceID) {
      id
      where
      filters {
        type
        condition
        options
      }
    }
  }
`

export const GET_ALL_CUSTOM_FIELDS_BY_TENANT_ID = gql`
  ${CUSTOM_FIELD_FRAGMENT}
  query {
    getAllCustomFieldsByTenantID {
      ...CustomFieldFragment
    }
  }
`

export const GET_ALL_SPACES_BY_TENANT_ID = gql`
  query {
    getAllSpacesByTenantID {
      id
      name
    }
  }
`

export const GET_TABLE_DETAILS = gql`
  query ($fieldID: String!) {
    getTableDetails(fieldID: $fieldID) {
      fieldID
      spaceName
      fieldName
      values {
        taskID
        data
      }
    }
  }
`

// Group Field Values
export const GET_GROUP_FIELD_VALUES = gql`
  query ($groupFieldID: String!, $taskID: String!) {
    getGroupFieldValues(groupFieldID: $groupFieldID, taskID: $taskID) {
      id
      taskID
      user {
        id
        fName
        lName
        profileURL
      }
      groupFieldID
      values {
        fieldID
        value
      }
    }
  }
`
