import { useState, useEffect } from "react"
import ReactHtmlParser from "react-html-parser"
import Linkify from "react-linkify"
import user from "../../../../assets/images/users/empty-user.png"
import LeftNav from "../leftNav"
import RightNav from "../rightNav"
import ImageGallery from "react-image-gallery"
import { Menu, Dropdown, Cascader } from "antd"
import toast from "react-hot-toast"
import uuid from "react-uuid"

const InstagramPreview = ({
  content,
  facebookAccount,
  mediaFiles,
  socialPosts,
}) => {
  const [images, setImages] = useState([])
  const [video, setVideo] = useState(undefined)

  useEffect(() => {
    if (mediaFiles && mediaFiles.length) {
      let newImages = []
      mediaFiles.forEach(media => {
        if (media.type === "video/mp4") {
          setVideo(media.preview)
        } else {
          newImages.push({ original: media.preview, thumbnail: media.preview })
        }
      })
      console.log("mediaFiles", mediaFiles)
      setImages(newImages)
    } else {
      setImages([])
    }
  }, [mediaFiles])

  const menu = account => {
    return (
      <Menu
        style={{
          fontFamily: "sans-serif",
        }}
      >
        <Menu.SubMenu className="mx-2 mt-1" title="Copy Link" key="0">
          {socialPosts?.postPages?.map(pagePost => {
            console.log("pagePost", pagePost)
            return (
              <Menu.Item
                key={uuid()}
                onClick={() => {
                  handleOption("0", pagePost.postURL)
                }}
              >
                <i className="fal fa-link me-1"></i>
                {pagePost.pageName ? pagePost.pageName : "Copy"}
              </Menu.Item>
            )
          })}
        </Menu.SubMenu>

        <Menu.SubMenu className="mx-2 mt-1" title="Open Link" key="1">
          {socialPosts?.postPages?.map(pagePost => {
            return (
              <Menu.Item
                key={uuid()}
                onClick={() => {
                  handleOption("1", pagePost.postURL)
                }}
              >
                <i className="fal fa-external-link me-1"></i>
                {pagePost.pageName ? pagePost.pageName : "Open"}
              </Menu.Item>
            )
          })}
        </Menu.SubMenu>
      </Menu>
    )
  }

  const handleOption = (key, url) => {
    if (url === "" || url === null || url === undefined) {
      toast.error("There's no post url to copy or open", {
        position: "top-right",
      })
      return
    }

    if (key === "0") {
      navigator.clipboard.writeText(url)
      toast.success("The post link was copied to your clipboard.", {
        position: "top-right",
      })
    } else if (key === "1") {
      window.open(url)
    }
  }

  return (
    <div className="post-template mb-3 post-template-desktop">
      <div className="linkedin-header d-flex justify-content-between align-items-center px-3 py-2">
        <div className="linkedin-header-left d-flex justify-content-center align-items-center">
          <img
            className="rounded-circle linkedin-dp  me-1"
            src={
              facebookAccount.profilePicURL
                ? facebookAccount.profilePicURL
                : user
            }
            alt="Header Avatar"
          />
          <div>
            <div className="font-weight-semibold font-size-14">
              {facebookAccount.firstName} {facebookAccount.lastName}
            </div>
          </div>
        </div>

        <div>
          <Dropdown
            placement="bottomRight"
            overlay={() => menu(facebookAccount)}
            trigger={["click"]}
            className="linkedin-option text-secondary"
          >
            <i className="fal fa-ellipsis-h font-size-22"></i>
          </Dropdown>
        </div>
      </div>

      <div className="linkedin-img mx-3">
        <ImageGallery
          infinite={false}
          showNav={true}
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          showIndex={false}
          items={images}
          renderLeftNav={(onClick, disabled) => {
            return <LeftNav onClick={onClick} disabled={disabled} />
          }}
          renderRightNav={(onClick, disabled) => {
            return <RightNav onClick={onClick} disabled={disabled} />
          }}
        />
        {video ? (
          <video
            style={{
              width: "100%",
            }}
            src={video}
            // type="video/mp4"
            controls
          ></video>
        ) : null}
      </div>

      <div className="linkedin-desc px-3 py-2">
        <Linkify>{ReactHtmlParser(content)}</Linkify>
      </div>

      <div className="d-flex justify-content-between ms-2 py-4">
        <div className="d-flex justify-content-center">
          <div className="d-flex justify-content-center align-items-center mx-2">
            {/* <i className="far fa-thumbs-up font-size-18 text-secondary"></i> */}
            <i className="far fa-heart font-size-18 "></i>
          </div>
          <div className="d-flex justify-content-center align-items-center mx-2">
            {/* <i className="far fa-comment-alt font-size-18 text-secondary"></i> */}
            <i className="far fa-comment font-size-18"></i>
          </div>
          <div className="d-flex justify-content-center align-items-center mx-2">
            {/* <i className="fal fa-share font-size-18 text-secondary"></i> */}
            <i className="far fa-paper-plane font-size-18"></i>
          </div>
        </div>
        <div className="me-3">
          <i className="far fa-bookmark font-size-18"></i>
        </div>
      </div>
    </div>
  )
}

export default InstagramPreview
