import { useMutation } from "@apollo/client"
import { Row, Col, Input } from "antd"
import { UPDATE_GROUP_FIELD } from "../../../../../graphql/mutation"
import React, { useEffect } from "react"
import { useState } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
const { TextArea } = Input

const GroupField = ({
  showGroupField,
  groupFieldObj,
  mainCustomFields,
  handleClose,
  spaceID,
  taskCustomFields,
  taskID,
}) => {
  const [groupField, setGroupField] = useState(null)
  const [fields, setFields] = useState(null)

  let concizeUser = JSON.parse(localStorage.getItem("concizeUser"))

  const [updateGroupField] = useMutation(UPDATE_GROUP_FIELD, {
    onCompleted: data => {
      console.log("data", data)
    },
    onError: err => {
      console.log("err", err)
    },
  })

  useEffect(() => {
    if (showGroupField) {
      let field

      mainCustomFields.forEach(ele => {
        if (ele.id === groupFieldObj.id) {
          field = ele
        }
      })
      console.log("taskCustomFields", taskCustomFields)
      setFields(field.fields)
      setGroupField(field)
    }
  }, [showGroupField])

  const handleNumberChange = (event, index) => {
    let val = event.target.value
    console.log("val", val)
    fields[index] = {
      ...fields[index],
      value: val,
    }
    setFields(fields)
  }

  const handleTextAreaChange = (event, index) => {
    let val = event.target.value
    console.log("val", val)
    fields[index] = {
      ...fields[index],
      value: val,
    }
    console.log(fields)
    setFields(fields)
  }

  const handleSave = () => {
    console.log("taskID", taskID)
    let input = {
      spaceID,
      taskID,
      groupFieldID: groupFieldObj.id,
    }
    let inputData = []
    fields.forEach(ele => {
      inputData.push({
        fieldID: ele.id,
        value: ele.value,
      })
    })
    input["datas"] = inputData
    console.log("input", input)
    // updateGroupField({
    //   variables: {
    //     input,
    //   },
    // })
  }

  const getValueByFieldID = id => {
    let groupFieldValues
    let val = ""
    for (let i = 0; i < taskCustomFields.length; i++) {
      let taskCustomField = taskCustomFields[i]
      if (taskCustomField.custFieldID === groupFieldObj.id) {
        groupFieldValues = taskCustomField.values
        break
      }
    }
    if (groupFieldValues && groupFieldValues.length) {
      for (let i = 0; i < groupFieldValues.length; i++) {
        let groupFieldValue = groupFieldValues[i]
        if (
          groupFieldValue.fieldID === id &&
          groupFieldValue.userID === concizeUser.id
        ) {
          val = groupFieldValue.value
          break
        }
      }
    }

    return val
  }

  return (
    <Modal isOpen={showGroupField}>
      <ModalHeader tag={"h4"} toggle={handleClose}>
        {groupField?.fieldName}
      </ModalHeader>
      <ModalBody>
        {fields &&
          fields.map((field, index) => {
            let fieldElement = null
            if (field.fieldType === "Number") {
              fieldElement = (
                <Input
                  className="rounded-3"
                  bordered
                  defaultValue={getValueByFieldID(field.id)}
                  onChange={e => handleNumberChange(e, index)}
                  style={{
                    width: "100%",
                  }}
                  type="number"
                  placeholder={field.fieldName}
                />
              )
            } else if (field.fieldType === "Text Area") {
              fieldElement = (
                <TextArea
                  defaultValue={getValueByFieldID(field.id)}
                  placeholder={field.fieldName}
                  onChange={e => handleTextAreaChange(e, index)}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  bordered
                  className="rounded-3"
                />
              )
            }
            return (
              <Row className="mb-2">
                <Col span={6}>{field.fieldName}</Col>
                <Col span={18}>{fieldElement}</Col>
              </Row>
            )
          })}
      </ModalBody>
      <ModalFooter>
        <div
          onClick={handleClose}
          className="btn btn-outline-secondary rounded-3"
        >
          Cancel
        </div>
        <div className="btn btn-primary rounded-3" onClick={handleSave}>
          Save
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default GroupField
