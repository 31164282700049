import React, { useState, useEffect } from "react"
import CustomToolTip from "./customToolTip"
import SubTaskList from "./subTaskList"
import AddSubTask from "./addSubTask"
import Comments from "./comments"
import moment from "moment"

import "antd/dist/antd.css"
import "./style.css"

// Reactstrap
import { Container, Label, Badge } from "reactstrap"

// Ant design
import { Select, Input, DatePicker, Row, Col, Menu, Dropdown } from "antd"
const { Option } = Select
const { RangePicker } = DatePicker

// Material UI
import Tooltip from "@material-ui/core/Tooltip"

import { GET_TASK, GET_ALL_USERS_BY_TENANT_ID } from "../../../graphql/queries"
import {
  UPDATE_TASK,
  ADD_AUDIT,
  ADD_ASSIGNEE,
  REMOVE_ASSIGNEE,
} from "../../../graphql/mutation"

import { useQuery, useMutation, useApolloClient } from "@apollo/client"
import CustomFieldsComponent from "./customField/customFieldsComponent"
import Activity from "./activity/activity"
import DrawerTitleField from "./rightDrawerComponents/drawerTitleField"
import DrawerDescriptionField from "./rightDrawerComponents/drawerDescriptionField"
import CommentList from "./comment/commentList"
import CopyToClipboard from "react-copy-to-clipboard"
import toast from "react-hot-toast"
import GroupFieldValueModal from "./groupFieldValueModal"
import useWindowDimensions from "../../../common/Hooks/getWindowDimensions"

const RightDrawer = ({
  openDrawer,
  selectedTask,
  handleCloseDrawer,
  updateMainTaskList,
  handleChangeSelectedTask,
  mainCustomFields,
  spaceID,
  workspaceID,
  tenantUsers,

  handleEditGroupCustomField,
}) => {
  const client = useApolloClient()

  const [isDateRange, setIsDateRange] = useState(false)
  const [stack, setStack] = useState(undefined)
  const { clientWidth, clientHeight } = useWindowDimensions();

  useEffect(() => {
    if (!selectedTask?.parentID) {
      setStack(undefined)
    }
    document.addEventListener("keydown", escapeFunction, false)
  }, [selectedTask])

  const escapeFunction = event => {
    if (event.keyCode === 27) {
      handleCloseDrawer()
    }
  }

  const [task, setTask] = useState(undefined)
  const [subTasks, setSubTasks] = useState(undefined)
  const [auditLogs, setAuditLogs] = useState(undefined)
  const [comments, setComments] = useState(undefined)
  const [customFieldValues, setCustomFieldValues] = useState(undefined)
  const [users, setUsers] = useState(undefined)
  const [assignees, setAssignees] = useState(undefined)
  // const [updatedTask] = useDebounce(task, 500)
  const [parentID, setParentID] = useState(undefined)
  const [auditInput, setAuditInput] = useState(undefined)
  const [commentData, setCommentData] = useState(undefined)
  const auditNomenclature = JSON.parse(
    localStorage.getItem("auditNomenClatureData")
  )

  const [isActivityOpen, setIsActivityOpen] = useState(false)

  useEffect(() => {
    setUsers(users)
  }, [tenantUsers])

  // ASSIGNEE API
  const {} = useQuery(GET_ALL_USERS_BY_TENANT_ID, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.getUsers) {
        setUsers(data.getUsers)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [addAssignee] = useMutation(ADD_ASSIGNEE, {
    errorPolicy: "all",
    onCompleted: data => {
      if (data && data.addAssignee) {
        if (data.addAssignee.isSubtask) {
          handleChangeSelectedTask(data.addAssignee)
        } else {
          updateMainTaskList(data.addAssignee)
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [removeAssignee] = useMutation(REMOVE_ASSIGNEE, {
    errorPolicy: "all",
    onCompleted: data => {
      if (data && data.removeAssignee) {
        if (data.removeAssignee.parentID === "") {
          updateMainTaskList(data.removeAssignee)
        } else {
          handleChangeSelectedTask(data.removeAssignee)
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  // AUDTI API
  const [addAudit] = useMutation(ADD_AUDIT, {
    onCompleted: data => {
      if (data && data.addAudit) {
        setAuditLogs(data.addAudit.audit)
        // if (data.addAudit.parentID === "") {
        //   updateMainTaskList(data.addAudit)
        // }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  // SECTION APIS
  const [updateTask] = useMutation(UPDATE_TASK, {
    onCompleted: data => {
      if (data && data.updateTask) {
        if (data.updateTask.isSubtask) {
          handleChangeDrawerDatas(data.updateTask)
          if (spaceID === "" || spaceID === null || spaceID === undefined) {
            console.log("spaceID", spaceID)
            updateMainTaskList(data.updateTask)
          }
        } else {
          updateMainTaskList(data.updateTask)
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  // SECTION Subscription
  /*   const {} = useSubscription(TASK_SUBSCRIPTION, {
    variables: {
      taskID: "61bb0f75d0191e772acacc9c",
    },
    onSubscriptionData: ({ subscriptionData }) => {
      console.log("subscriptionData", subscriptionData)
    },
    onError: err => {
      console.log("err", err)
    },
  })
  */

  useEffect(() => {
    if (selectedTask && openDrawer) {
      if (selectedTask.startDate !== "") {
        setIsDateRange(true)
      } else {
        setIsDateRange(false)
      }

      setParentID(selectedTask.id)
      setTask(selectedTask)

      setAuditLogs(selectedTask.audit)
      let assigneeList = []
      if (selectedTask.assignees && selectedTask.assignees.length) {
        selectedTask.assignees.forEach(ele => {
          if (ele.user) {
            let name = ele.user.fName.concat(" ", ele.user.lName)
            if (ele.user.isDeleted) {
              name += " (Deleted User)"
            }
            console.log("ele", ele)
            let assignee = {
              value: ele.id,
              label: name,
              key: ele.id,
              userID: ele.user.id,
            }
            assigneeList.push(assignee)
          }
        })
      }
      console.log("assigneeList", assigneeList)
      setAssignees(assigneeList)

      setSubTasks(selectedTask.subTasks)
      setComments(selectedTask.comments)
      setCustomFieldValues(selectedTask.customFieldValues)
      setCommentData(undefined)
      setIsActivityOpen(false)
    }
  }, [selectedTask])

  useEffect(() => {
    var hash = window.location.hash
    console.log("hash", hash)

    if (comments && openDrawer && selectedTask && hash && hash !== "") {
      document.getElementById(hash.replace("#", ""))?.scrollIntoView(true)
    }
  }, [openDrawer, comments])

  const handleUpdateTask = (updatedTask, newAudit) => {
    delete updatedTask.assignees
    delete updatedTask.id
    delete updatedTask.__typename
    delete updatedTask.subTasks
    delete updatedTask.comments
    delete updatedTask.audit
    delete updatedTask.customFieldValues
    delete updatedTask.space
    //console.log("updatedTask", updatedTask)
    updateTask({
      variables: {
        input: updatedTask,
        taskID: selectedTask.id,
        auditInput: newAudit,
      },
    })
  }

  const handleAllFieldChanges = inputTask => {
    setTask({ ...inputTask })
    // handleUpdateTask(inputTask, auditInput)
  }

  const handleBackButtonClick = () => {
    if (stack && stack.length) {
      let newStack = [...stack]
      const lastTask = newStack[newStack.length - 1]

      const {} = client
        .query({
          fetchPolicy: "cache-only",
          query: GET_TASK,
          variables: {
            id: lastTask.id,
          },
        })
        .then(res => {
          if (res && res.data) {
            let data = res.data
            if (data && data.getTask) {
              let taskData = data.getTask
              handleChangeDrawerDatas(taskData)
              newStack.pop()
              setStack(newStack)
            }
          }
        })
    }
  }

  // pID - parentID
  const addToStack = () => {
    if (stack && stack.length) {
      setStack([...stack, selectedTask])
    } else {
      setStack([selectedTask])
    }
  }

  const handleAssigneeSelect = event => {
    let auditInput = {
      auditType: 3,
      to: event.label,
    }

    addAssignee({
      variables: {
        input: {
          taskID: selectedTask.id,
          userID: event.key,
        },
        auditInput,
      },
    })
  }

  const handleAssigneeDeselect = assigneeID => {
    /* let userID
    selectedTask.assignees?.forEach(ele => {
      if (ele.user) {
        let user = ele.user
        if (user.fName.concat(" ", user.lName) === name) {
          console.log("ele", ele)
          userID = ele.id
          return
        }
      }
    }) */
    console.log("assigneeID", assigneeID)
    if (assigneeID) {
      removeAssignee({
        variables: {
          assigneeID,
          taskID: selectedTask.id,
        },
      })
    }
  }

  const handleDateChange = dateStr => {
    let taskValue = { ...task }
    taskValue["startDate"] = dateStr[0]
    taskValue["endDate"] = dateStr[1]

    const auditInput = {
      auditType: 10,
      from: `${selectedTask.startDate} ${selectedTask.endDate}`,
      to: `${dateStr[0]} to ${dateStr[1]}`,
    }
    setAuditInput(auditInput)
    handleAllFieldChanges(taskValue)
    handleUpdateTask(taskValue, auditInput)
  }

  const handleEndDateChange = dateString => {
    let taskValue = { ...task }
    taskValue["endDate"] = dateString

    const auditInput = {
      auditType: 4,
      from: selectedTask.endDate,
      to: dateString,
    }
    setAuditInput(auditInput)
    handleAllFieldChanges(taskValue)
    handleUpdateTask(taskValue, auditInput)
  }

  const statusFormatter = status => {
    let element = ""
    let className = "bg-secondary"
    switch (status) {
      case "Completed":
        className = "bg-success"
        break
      case "Pending":
        className = "bg-warning"
        break
      case "Delay":
        className = "bg-danger"
        break
      case "In Progress":
        className = "bg-orange"
        break
      default:
        className = "bg-secondary"
        break
    }
    element = (
      <Badge
        style={{
          padding: "6px 4px",
          width: "100%",
          fontSize: "12px",
        }}
        key={status}
        className={className}
      >
        {status}
      </Badge>
    )
    return element
  }

  const handleMarkComplete = text => {
    let taskValue = { ...task }
    taskValue["status"] = text

    const auditInput = {
      auditType: 5,

      from: selectedTask.status,
      to: text,
    }
    setAuditInput(auditInput)

    handleAllFieldChanges(taskValue)
    handleUpdateTask(taskValue, auditInput)
  }

  const handleStatusChange = value => {
    let taskValue = { ...task }
    taskValue["status"] = value

    const auditInput = {
      auditType: 5,
      from: selectedTask.status,
      to: value,
    }
    setAuditInput(auditInput)

    handleAllFieldChanges(taskValue)
    handleUpdateTask(taskValue, auditInput)
  }

  const handleChangeDrawerDatas = subTaskDetail => {
    handleChangeSelectedTask(subTaskDetail)
  }

  const menu = (
    <Menu
      onClick={e => {
        handleThreeDotsClick(e.key)
      }}
      style={{ borderRadius: "4px" }}
    >
      <Menu.Item key="0">
        <i className="fad fa-history me-2"></i>Activity
      </Menu.Item>
    </Menu>
  )

  const handleThreeDotsClick = key => {
    if (key === "0") {
      setIsActivityOpen(true)
    }
  }

  const handleActivityClose = () => {
    setIsActivityOpen(false)
  }

  const handleUpdateComment = updateComment => {
    console.log("update ---->", updateComment)
    setCommentData({ ...updateComment })
  }

  const handleCopyTaskLink = () => {
    toast.success("The task link was copied to your clipboard", {
      position: "bottom-left",
    })
  }

  const [showGroupField, setShowGroupField] = useState(false)
  const [selectedGroupField, setSelectedGroupField] = useState(null)

  const handleEditGroupField = id => {
    let groupField = null
    for (let i = 0; i < mainCustomFields.length; i++) {
      let field = mainCustomFields[i]
      if (field.id === id) {
        groupField = field
        break
      }
    }
    handleEditGroupCustomField(groupField)
  }

  // Group Fields
  const groupFieldsMenu = (
    <Menu style={{ borderRadius: "4px" }}>
      <Menu.ItemGroup title="Group Fields">
        {mainCustomFields &&
          mainCustomFields.map(mainCustomField => {
            if (mainCustomField.fieldType !== "Group") {
              return null
            }
            return (
              <div
                key={mainCustomField.id}
                className="d-flex justify-content-between align-items-center"
              >
                <div
                  className="me-2 subtask-hover cursor-pointer py-2 px-2 rounded-3 w-100 me-2"
                  onClick={() => {
                    handleGroupFieldClick(mainCustomField.id)
                  }}
                >
                  {mainCustomField.fieldName}
                </div>
                <div
                  onClick={() => handleEditGroupField(mainCustomField.id)}
                  className="subtask-hover cursor-pointer px-2 py-1 rounded-3"
                >
                  <i className="fal fa-pen"></i>
                </div>
              </div>
              /* <Menu.Item key={mainCustomField.id} className="me-2">
                <div className="d-flex justify-content-between align-items-center">
                  <div> {mainCustomField.fieldName}</div>
                  <div
                    onClick={handleEditGroupField}
                    style={{
                      position: "absolute",
                      right: "0",
                    }}
                  >
                    <i className="fas fa-ellipsis-v"></i>
                  </div>
                </div>
              </Menu.Item> */
            )
          })}
      </Menu.ItemGroup>
    </Menu>
  )

  const handleGroupFieldClick = id => {
    for (let i = 0; i < mainCustomFields.length; i++) {
      let field = mainCustomFields[i]
      if (field.id === id) {
        setSelectedGroupField(field)
        break
      }
    }

    setShowGroupField(true)
  }

  const handleClearCommentData = () => {
    setCommentData(null)
  }

  return (
    <Container
      key={selectedTask?.id}
      fluid
      className="d-flex justify-content-center pb-3"
    >
      <div
        className={openDrawer ? "shadow-sm pb-2 col-12 col-lg-5" : "col-0"}
        style={{      
          height: "calc(100vh - 0px)",
          overflowX: "hidden",
          transition: "width 0.5s",
          backgroundColor: "#f9f8f8",
          position: "fixed",
          right: "0",
          top: "70px",
          bottom: "0",
          zIndex: 1000,
        }}
      >
        {/* NOTE Audit */}
        <Activity
          auditLogs={auditLogs}
          showActivity={isActivityOpen}
          handleClose={handleActivityClose}
          auditNomenclature={auditNomenclature}
          taskCustomFields={customFieldValues}
        />
        {/* NOTE Mark complete button */}
        <div
          className="bg-white px-4 d-flex justify-content-between align-items-center col-lg-5 col-12"
          style={{
            height: "60px",
            position: "fixed",
            width: !openDrawer ? "0%" : "",
            zIndex: "99",
            transition: "width 0.5s",
            borderBottom: "1px solid lightgrey",
          }}
        >
          <div>
            <div
              className={
                task && task.status === "Completed"
                  ? "rounded-3 mark-complete-btn mark-complete-btn-fixed"
                  : "rounded-3 mark-complete-btn"
              }
              size="middle"
              onClick={() => {
                if (task && task.status !== "Completed") {
                  handleMarkComplete("Completed")
                } else {
                  handleMarkComplete("")
                }
              }}
            >
              <i className="mdi mdi-check me-1"></i>
              {task && task.status === "Completed"
                ? "Completed"
                : "Mark Completed"}
            </div>
          </div>
          <div className="d-flex">
            <Dropdown overlay={groupFieldsMenu} trigger={["click"]}>
              <Tooltip
                enterDelay={300}
                arrow
                placement="top"
                title="Group Fields"
              >
                <div className="icon-button">
                  <i className="fal fa-layer-group font-size-16"></i>
                </div>
              </Tooltip>
            </Dropdown>

            <Tooltip enterDelay={300} arrow placement="top" title="Copy link">
              <CopyToClipboard
                className="icon-button"
                text={
                  spaceID
                    ? `${window.location.href}`
                    : `${window.location.origin}/${workspaceID}/space-list/${selectedTask?.space?.id}/?taskID=${selectedTask?.id}&taskName=${encodeURIComponent(selectedTask?.title).replace(/%20/g, '+')}`
                }
              >
                <div onClick={handleCopyTaskLink}>
                  <i className="fal fa-link font-size-16"></i>
                </div>
              </CopyToClipboard>
            </Tooltip>

            <Tooltip enterDelay={300} arrow placement="top" title="Activity">
              <div
                onClick={() => setIsActivityOpen(true)}
                className="icon-button"
              >
                <i className="fal fa-stream font-size-16"></i>
              </div>
            </Tooltip>
            <Tooltip enterDelay={300} arrow placement="top" title="Options">
              <Dropdown overlay={menu} trigger={["click"]}>
                <div hidden={true} className="icon-button">
                  <i className="fal fa-ellipsis-h font-size-24"></i>
                </div>
              </Dropdown>
            </Tooltip>

            <Tooltip
              enterDelay={300}
              arrow
              placement="top"
              title="Close details (Esc)"
            >
              <div className="icon-button" onClick={handleCloseDrawer}>
                <i className="fal fa-arrow-to-right font-size-20"></i>
              </div>
            </Tooltip>
          </div>
        </div>
        {/* TODO */}
        <div
          style={{
            background: "#fff",
            position: clientWidth < 992 ? "fixed" : "absolute",
            right: "0",
            top: clientWidth < 992 ? "8rem" : "3rem",
            paddingTop: "1.5rem",
            overflow: "scroll",
            bottom: "164px",
            width: openDrawer ? "100%" : "0%",
            maxHeight: clientWidth < 992 ? "calc(100vh - 208px)" : "calc(100vh - 230px)"
          }}
          className="right-drawer"
        >
          {/* NOTE Back button */}
          <Row
            className="ms-2 px-4 mb-2"
            justify="start"
            // hidden={stack && stack.length > 0 ? false : true}
            style={{
              display: stack && stack.length > 0 ? "block" : "none",
            }}
          >
            <Col>
              <div
                onClick={handleBackButtonClick}
                className="customBtn p-1 px-2 d-inline-block"
              >
                <i className="fas fa-arrow-left me-2"></i>
                <span className="pe-1">Back</span>
              </div>
            </Col>
          </Row>
          {/* NOTE Title */}
          <div className="px-4">
            <DrawerTitleField data={task} handleUpdateTask={handleUpdateTask} />
          </div>

          <Row className="px-4 mb-2 mt-3">
            <Col span={6} className="pt-2">
              <CustomToolTip
                text="Assignee"
                subText="Add an assignee to designate this task's owner."
              />
            </Col>
            <Col span={16}>
              <Select
                dropdownStyle={{ borderRadius: "6px" }}
                className="assigneeHover"
                bordered={false}
                size="middle"
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="No assignee"
                value={assignees ? assignees : null}
                // labelInValue={true}
                filterOption={(input, option) => {
                  if (option && option.label) {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                }}
                onSelect={(_, e) => handleAssigneeSelect(e)}
                onDeselect={key => handleAssigneeDeselect(key)}
                optionLabelProp="label"
              >
                {users
                  ? users.map(ele => {
                      if (assignees && assignees.length) {
                        for (let i = 0; i < assignees.length; i++) {
                          let a = assignees[i]
                          if (a.userID === ele.id) {
                            return null
                          }
                        }
                      }
                      let userName = ele.fName.concat(" ", ele.lName)
                      if (ele.isDeleted) {
                        userName += " (Deleted User)"
                      }

                      return (
                        <Option value={ele.id} label={userName} key={ele.id}>
                          <div
                            key={ele.id}
                            className="demo-option-label-item d-flex align-items-center"
                          >
                            {ele.profileURL ? (
                              <img
                                src={ele.profileURL}
                                alt=""
                                width="36"
                                height="36"
                                className="rounded-circle"
                              />
                            ) : (
                              <div className="avatar-xs">
                                <span
                                  style={{
                                    backgroundColor: "red",
                                  }}
                                  className="avatar-title rounded-circle bg-soft bg-success text-success font-size-14"
                                >
                                  {ele.fName !== undefined
                                    ? ele.fName.charAt(0)
                                    : "?"}
                                </span>
                              </div>
                            )}
                            <div className="ms-2 font-size-13">{userName}</div>
                          </div>
                        </Option>
                      )
                    })
                  : ""}
              </Select>
            </Col>
          </Row>

          {/* NOTE Due date */}
          <Row className="p-2 my-1 px-4" align="middle">
            <Col span={6}>
              <CustomToolTip
                text="Due Date"
                subText="Due date is an easy way to show when this task should be completed"
              />
            </Col>
            <Col>
              <div className="dueDateHover me-2">
                {isDateRange ? (
                  <RangePicker
                    value={[
                      task?.startDate !== ""
                        ? moment(`${task.startDate}`, "DD-MM-YYYY")
                        : "",
                      task?.endDate !== ""
                        ? moment(`${task.endDate}`, "DD-MM-YYYY")
                        : "",
                    ]}
                    format="DD-MM-YYYY"
                    bordered={false}
                    onChange={(_, dateString) => {
                      handleDateChange(dateString)
                    }}
                    separator={<span>-</span>}
                  />
                ) : (
                  <DatePicker
                    style={{ cursor: "pointer" }}
                    placeholder="End Date"
                    bordered={false}
                    value={
                      task?.endDate !== ""
                        ? moment(`${task?.endDate}`, "DD-MM-YYYY")
                        : ""
                    }
                    size="middle"
                    onChange={(_, dateString) => {
                      handleEndDateChange(dateString)
                    }}
                    allowClear={false}
                    // format="MMM Do, YYYY"
                    format="DD-MM-YYYY"
                  />
                )}
              </div>
            </Col>
            <Col>
              <input
                type="checkbox"
                style={{ cursor: "pointer" }}
                className="form-check-input"
                checked={isDateRange}
                onChange={() => setIsDateRange(!isDateRange)}
              />
              <Label
                onClick={() => setIsDateRange(!isDateRange)}
                className="form-check-label text-secondary font-size-12 ms-1"
              >
                Date Range
              </Label>
            </Col>
          </Row>
          {/* NOTE Status */}
          <Row className="p-2 my-1 px-4">
            <Col span={6} className="pt-2">
              <CustomToolTip
                text="Status"
                subText="Track the status of each task."
              />
            </Col>
            <Col className="ms-1">
              <Select
                className="statusHover"
                size="middle"
                bordered={false}
                value={task?.status ? task.status : undefined}
                style={{ width: "120px" }}
                onChange={handleStatusChange}
                placeholder="No Status"
                dropdownRender={menu => (
                  <div className="d-flex flex-column ">{menu}</div>
                )}
                // showSearch
                // filterOption={(input, option) => {
                //   console.log(option)
                //   if (option && option.value) {
                //     return option.value
                //       .toLowerCase()
                //       .includes(input.toLowerCase())
                //   }
                // }}
              >
                {["Completed", "Pending", "Delay", "In Progress"].map(
                  (item, index) => {
                    return (
                      <Option value={item} key={index}>
                        {statusFormatter(item)}
                      </Option>
                    )
                  }
                )}
              </Select>
            </Col>
          </Row>

          {/* NOTE Description */}
          <Row className="p-2 mt-3 px-4">
            <Col span={6} className="pt-2">
              <CustomToolTip
                text="Description"
                subText="Add an important details about this task in description."
              />
            </Col>
            <Col span={18}>
              <DrawerDescriptionField
                data={task}
                handleUpdateTask={handleUpdateTask}
              />
            </Col>
          </Row>

          {/* NOTE Custom Fields */}
          {openDrawer && selectedTask ? (
            <CustomFieldsComponent
              taskCustomFields={customFieldValues}
              mainCustomFields={mainCustomFields}
              taskID={selectedTask.id}
              updateMainTaskList={updateMainTaskList}
              addAudit={addAudit}
              assignees={assignees}
              handleAssigneeSelect={handleAssigneeSelect}
              handleAssigneeDeselect={handleAssigneeDeselect}
              users={users}
              spaceID={spaceID}
            />
          ) : null}

          {/* NOTE Subtasks */}
          <Row className="p-2 px-4">
            <Col span={24}>
              <CustomToolTip
                text="Subtasks"
                subText="Add an sub task to break up work into smaller parts."
              />
            </Col>
          </Row>

          {/* NOTE Subtasks list */}
          <SubTaskList
            subTasksProp={subTasks}
            handleChangeDrawerDatas={handleChangeDrawerDatas}
            addToStack={addToStack}
            parentTaskID={parentID}
            updateMainTaskList={updateMainTaskList}
          />

          {/* NOTE Add Subtask button */}
          <AddSubTask
            handleChangeDrawerDatas={handleChangeDrawerDatas}
            updateMainTaskList={updateMainTaskList}
            parentID={parentID}
            customFields={mainCustomFields}
            spaceID={spaceID}
          />
          <CommentList
            task={task}
            comments={comments}
            handleUpdateComment={handleUpdateComment}
            updateMainTaskList={updateMainTaskList}
            handleChangeDrawerDatas={handleChangeDrawerDatas}
          />
        </div>

        {/* NOTE Audit and Comments */}
        <div
          style={{
            backgroundColor: "#f9f8f8",
          }}
        >
          <Comments
            users={users}
            task={task}
            updateMainTaskList={updateMainTaskList}
            handleChangeDrawerDatas={handleChangeDrawerDatas}
            openDrawer={openDrawer}
            commentData={commentData}
            handleClearCommentData={handleClearCommentData}
          />
        </div>
      </div>
      <GroupFieldValueModal
        showGroupField={showGroupField}
        handleClose={() => setShowGroupField(false)}
        selectedGroupField={selectedGroupField}
        taskID={selectedTask?.id}
      />
    </Container>
  )
}

export default RightDrawer
