import React, { useState, useEffect } from "react"
import CustomToolTip from "../customToolTip"
import { Row, Col, DatePicker, Select, Input } from "antd"
import moment from "moment"

import { useMutation } from "@apollo/client"
import { UPDATE_CUSTOM_FIELD } from "../../../../graphql/mutation"
import DateField from "./components/dateField"
import TextField from "./components/textField"
import Dropdown from "./components/dropdownField"
import DropdownMultiField from "./components/dropdownMultiField"
import NumberField from "./components/numberField"
import CheckboxField from "./components/checkboxField"
import TextAreaField from "./components/textAreaField"
import DrawerTextField from "./components/drawerTextField"
import GroupField from "./components/groupField"

const { Option } = Select

const CustomFieldsComponent = React.memo(
  ({
    taskCustomFields,
    mainCustomFields,
    taskID,
    updateMainTaskList,
    addAudit,
    assignees,
    handleAssigneeDeselect,
    handleAssigneeSelect,
    users,
    spaceID,
  }) => {
    const [auditInput, setAuditInput] = useState(undefined)
    const [showGroupField, setShowGroupField] = useState(false)
    const [groupField, setGroupField] = useState(null)

    // SECTION APIS
    const [updateCustomField] = useMutation(UPDATE_CUSTOM_FIELD, {
      onCompleted: data => {
        if (data && data.updateCustomField) {
          updateMainTaskList(data.updateCustomField)
        }
      },
      onError: err => {
        console.log("err", err)
      },
    })

    function updateAuditInput(field, newVal, taskFieldObj) {
      let newAuditInput = {
        auditType: -1,
        taskID: taskID,
        from:
          taskFieldObj && taskFieldObj.value !== ""
            ? `Updated the ${field.fieldName} from ${taskFieldObj.value}`
            : `Updated the ${field.fieldName}`,
        to: `to ${newVal}`,
      }
      setAuditInput(newAuditInput)
    }

    const handleUpdateCustomField = updateObj => {
      updateCustomField({
        variables: {
          id: updateObj.id,
          taskID: updateObj.taskID,
          value: updateObj.value,
          auditInput: updateObj.auditInput,
        },
      })
    }

    return (
      <div>
        {taskCustomFields
          ? taskCustomFields.map((taskCustomField, index) => {
              let taskFieldObj = mainCustomFields?.find(
                ele => ele.id === taskCustomField.custFieldID
              )
              if (!taskFieldObj) {
                return null
              }
              return (
                <div key={index}>
                  <Row className="p-2 my-3 px-4" align="middle">
                    {taskFieldObj.fieldType === "Date" ? (
                      <DateField
                        handleUpdateCustomField={handleUpdateCustomField}
                        taskID={taskID}
                        taskFieldObj={taskFieldObj}
                        taskCustomField={taskCustomField}
                      />
                    ) : null}
                    {taskFieldObj.fieldType === "Dropdown" ? (
                      <Dropdown
                        handleUpdateCustomField={handleUpdateCustomField}
                        taskID={taskID}
                        taskFieldObj={taskFieldObj}
                        taskCustomField={taskCustomField}
                      />
                    ) : null}
                    {taskFieldObj.fieldType === "Dropdown Multi" ? (
                      <DropdownMultiField
                        handleUpdateCustomField={handleUpdateCustomField}
                        taskID={taskID}
                        taskFieldObj={taskFieldObj}
                        taskCustomField={taskCustomField}
                      />
                    ) : null}
                    {taskFieldObj.fieldType === "Text" ? (
                      taskFieldObj.id.includes("default_task_id") ? (
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <DrawerTextField
                            handleUpdateCustomField={handleUpdateCustomField}
                            taskID={taskID}
                            taskFieldObj={taskFieldObj}
                            taskCustomField={taskCustomField}
                          />
                        </div>
                      ) : (
                        <TextField
                          handleUpdateCustomField={handleUpdateCustomField}
                          taskID={taskID}
                          taskFieldObj={taskFieldObj}
                          taskCustomField={taskCustomField}
                        />
                      )
                    ) : null}
                    {taskFieldObj.fieldType === "Text Area" ? (
                      <TextAreaField
                        handleUpdateCustomField={handleUpdateCustomField}
                        taskID={taskID}
                        taskFieldObj={taskFieldObj}
                        taskCustomField={taskCustomField}
                      />
                    ) : null}
                    {taskFieldObj.fieldType === "Number" ? (
                      <NumberField
                        handleUpdateCustomField={handleUpdateCustomField}
                        taskID={taskID}
                        taskFieldObj={taskFieldObj}
                        taskCustomField={taskCustomField}
                      />
                    ) : null}
                    {taskFieldObj.fieldType === "Checkbox" ? (
                      <CheckboxField
                        handleUpdateCustomField={handleUpdateCustomField}
                        taskID={taskID}
                        taskFieldObj={taskFieldObj}
                        taskCustomField={taskCustomField}
                      />
                    ) : null}
                    {/* {taskFieldObj.fieldType === "Group" ? (
                      <React.Fragment>
                        <Col span={6}>{taskFieldObj.fieldName}</Col>
                        <Col span={6}>
                          <div
                            onClick={() => {
                              console.log("asdasds", taskFieldObj)
                              setGroupField(taskFieldObj)
                              setShowGroupField(true)
                            }}
                            className="btn btn-primary btn-sm rounded-3"
                          >
                            Open
                          </div>
                        </Col>
                      </React.Fragment>
                    ) : null} */}
                  </Row>
                </div>
              )
            })
          : null}
        <GroupField
          taskCustomFields={taskCustomFields}
          showGroupField={showGroupField}
          groupFieldObj={groupField}
          mainCustomFields={mainCustomFields}
          handleClose={() => setShowGroupField(false)}
          spaceID={spaceID}
          taskID={taskID}
        />
      </div>
    )
  }
)

export default React.memo(CustomFieldsComponent)
