import React, { useState, useEffect } from "react"
import moment from "moment"
import { Menu, Dropdown } from "antd"
import { useMutation } from "@apollo/client"
import { DELETE_COMMENT } from "../../../../graphql/mutation"
import Linkify from "react-linkify"
import { MentionsInput, Mention } from "react-mentions"
import Editor from "@draft-js-plugins/editor"
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
  CompositeDecorator,
} from "draft-js"
import draftToHtml from "draftjs-to-html"
import { stateToHTML } from "draft-js-export-html"
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "@draft-js-plugins/mention"
import "@draft-js-plugins/mention/lib/plugin.css"
import "@draft-js-plugins/static-toolbar/lib/plugin.css"
import createLinkifyPlugin from "@draft-js-plugins/linkify"
import createEmojiPlugin from "@draft-js-plugins/emoji"
import ReactHtmlParser from "react-html-parser"
import { flattenDeep } from "lodash"
// import "@draft-js-mention-plugin/lib/plugin.css"

const mentionPlugin = createMentionPlugin({
  entityMutability: "IMMUTABLE",
  theme: {
    mention: "comment-mention",
  },
  mentionPrefix: "@",
  supportWhitespace: true,
})

const linkifyPlugin = createLinkifyPlugin()
const emojiPlugin = createEmojiPlugin({
  useNativeArt: true,
})

const SingleComment = ({
  comment,
  handleUpdateComment,
  task,
  updateMainTaskList,
  handleChangeDrawerDatas,
}) => {
  const [editorState, setEditorState] = useState(null)
  var hash = window.location.hash

  useEffect(() => {
    let fromRawData
    try {
      let parsedData = JSON.parse(comment.comment)
      fromRawData = convertFromRaw(parsedData)
    } catch (e) {
      fromRawData = ContentState.createFromText(comment.comment)
    }
    setEditorState(EditorState.createWithContent(fromRawData))
  }, [comment])

  const [deleteComment] = useMutation(DELETE_COMMENT, {
    onCompleted: data => {
      if (data && data.deleteComment) {
        let taskWithDeletedComment = data.deleteComment
        if (taskWithDeletedComment.isSubtask) {
          handleChangeDrawerDatas(taskWithDeletedComment)
        } else {
          updateMainTaskList(taskWithDeletedComment)
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  useEffect(() => {
    if (task && comment) {
      const plugins = [mentionPlugin, linkifyPlugin]
      const decorators = flattenDeep(plugins.map(plugin => plugin.decorators))
      const decorator = new CompositeDecorator(
        decorators.filter((decorator, index) => index !== 1)
      )

      let fromRawData
      // try {
      let parsedData = JSON.parse(comment.comment)
      fromRawData = convertFromRaw(parsedData)
      // } catch (e) {
      // fromRawData = ContentState.createFromText(comment.comment)
      // }
      // setEditorState(EditorState.createWithContent(fromRawData, decorator))
    }
  }, [])

  const handleDeleteComment = comment => {
    let auditInput = {
      auditType: 9,
      from: comment.comment,
    }
    console.log("task", task)
    deleteComment({
      variables: {
        id: comment.id,
        taskID: task.id,
        auditInput,
      },
    })
  }

  const getLocalDate = utcDate => {
    let localDate = moment(utcDate).fromNow()
    return localDate
  }

  const handleMenuClick = (key, comment) => {
    if (key === "1") {
      handleUpdateComment(comment)
    } else if (key === "2") {
      handleDeleteComment(comment)
    }
  }

  const menu = comment => (
    <Menu
      style={{
        fontSize: "12px",
        borderRadius: "6px",
        width: "150px",
      }}
      onClick={e => {
        handleMenuClick(e.key, comment)
      }}
    >
      <Menu.ItemGroup title="Comment options">
        <Menu.Item key={1}>
          <i className="fal fa-edit me-2"></i>
          <span>Edit</span>
        </Menu.Item>
        <Menu.Item key={2} className="text-danger">
          <i className="fal fa-trash-alt me-2"></i>
          <span> Delete</span>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )

  return (
    <div
      id={comment.id}
      key={comment.id}
      style={{
        background: "#f9f8f8",
      }}
      className={`pb-1 ${hash.includes(comment.id) ? "animate-flicker" : ""}`}
    >
      <div
        className="media d-flex align-items-center py-2"
        style={{
          borderBottom: "1px solid #f1f1f1",
          width: "94%",
          margin: "0 auto",
        }}
      >
        <div className="media-body p-2">
          <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex" style={{ width: "95%" }}>
              {comment.user && comment.user.profileURL ? (
                <img
                  src={comment.user?.profileURL}
                  alt=""
                  width="36"
                  height="36"
                  className="rounded-circle me-2"
                />
              ) : (
                <div
                  className="avatar-xs me-2"
                  style={{
                    width: "36px",
                    height: "36px ",
                  }}
                >
                  <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-18">
                    {comment.user.fName !== undefined
                      ? comment.user.fName.charAt(0)
                      : "?"}
                  </span>
                </div>
              )}

              {/* <div className="avatar-xs rounded-circle me-2">
                <span
                  className={
                    "avatar-title rounded-circle bg-soft bg-success text-success font-size-22"
                  }
                >
                  {comment ? comment.user.fName.charAt(0) : "?"}
                </span>
              </div> */}

              <div
                style={{
                  width: "90%",
                }}
              >
                <h6 className="mb-1 font-size-14">
                  <strong>{comment ? comment.user.fName : null}</strong>
                  <small className="ms-2 text-secondary">
                    {comment ? getLocalDate(comment.createdAt) : null}
                  </small>
                </h6>

                <div
                  style={
                    {
                      // whiteSpace: "pre",
                    }
                  }
                  className="comment-text"
                >
                  {editorState && (
                    <Editor
                      // editorKey={"editor"}
                      // editorState={EditorState.createWithContent(
                      //   convertFromRaw(JSON.parse(comment.comment))
                      // )}
                      editorKey={"editor-2"}
                      editorState={editorState}
                      readOnly
                      onChange={editorState => setEditorState(editorState)}
                      plugins={[mentionPlugin, linkifyPlugin, emojiPlugin]}
                    />
                  )}

                  {/* <Linkify>
                    {ReactHtmlParser(
                      stateToHTML(editorState.getCurrentContent())
                    )}
                  </Linkify> */}
                </div>
              </div>
            </div>

            <h6
              style={{
                cursor: "pointer",
              }}
              className="me-2"
            >
              {comment.isUserComment ? (
                <Dropdown
                  overlay={() => menu(comment)}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <i className="fas fa-chevron-down font-size-10 p-2 custom-hover"></i>
                </Dropdown>
              ) : null}
            </h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleComment
