import React, { useState, useEffect, useContext } from "react"
import MetaTags from "react-meta-tags"
import firebase from "firebase/app"
import { useHistory } from "react-router-dom"

// Add the Firebase products that you want to use
import "firebase/auth"

import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.png"
import { withRouter, Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useMutation, useLazyQuery } from "@apollo/client"
import { SIGN_IN } from "../../graphql/mutation"
import { getFirebaseBackend } from "helpers/firebase_helper"
import { GET_AUDIT_NOMENCLATURE } from "../../graphql/queries"
import { GlobalContext } from "contexts/index"
const NewLogin = props => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const { updateProfilePicCtx } = useContext(GlobalContext)

  const updateCache = (cache, { data }) => {
    let role = ""
    if (data && data.signIn) {
      localStorage.setItem("concizeUser", JSON.stringify(data.signIn))
      role = data.signIn.role
    }
    firebase
      .auth()
      .signInWithCustomToken(data.signIn.token)
      .then(
        firebaseData => {
          localStorage.setItem("authUser", JSON.stringify(firebaseData.user))
          if (data?.signIn?.profileURL) {
            updateProfilePicCtx(data.signIn.profileURL)
          } else {
            updateProfilePicCtx(null)
          }
          // checkIsSessionExpired()
          loginRedirect(data)
        },
        error => {
          console.log("error", error)
          setError(error.message)
          setLoading(false)
        }
      )
  }

  const loginRedirect = data => {
    if (props.location.state) {
      let RedirectUrl = props.location.state.from.pathname
      let params = props.location.state.from.search
      if (props.location.state.from.hash) {
        params = params + props.location.state.from.hash
      }
      history.push({
        pathname: RedirectUrl,
        search: params,
      })
    } else if (data && data.signIn && data.signIn.lastWorkspace) {
      let lastWorkspace = data.signIn.lastWorkspace
      history.push(`${lastWorkspace.id}/my-tasks`)
    } else {
      history.push("/profile")
    }
  }
  const [getAuditNomenclature] = useLazyQuery(GET_AUDIT_NOMENCLATURE, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.getAuditNomenclature) {
        console.log("data---getAuditNomenclature>", data)
        let auditNomenclatureMap = new Map()
        for (const el of data.getAuditNomenclature) {
          auditNomenclatureMap.set(el.type, el)
        }
        localStorage.setItem(
          "auditNomenClatureData",
          JSON.stringify(Object.fromEntries(auditNomenclatureMap))
        )
      }
    },
  })

  const [signInToConcizeDB] = useMutation(SIGN_IN, {
    update: updateCache,
    onError: err => {
      setLoading(false)
      setError(err.message)
    },
  })

  const handleAlertClose = () => {
    setError(null)
  }

  const handleValidSubmit = values => {
    setLoading(true)
    setError(null)
    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(
        user => {
          signInToConcizeDB({
            variables: {
              input: {
                firebaseID: user.user.uid,
              },
            },
          })
          getAuditNomenclature()
        },
        error => {
          setError(error.message)
          setLoading(false)
        }
      )
  }

  const handlePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Concize</title>
      </MetaTags>
      <div className="pt-5">
        <Container>
          <div
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "99",
              textAlign: "center",
            }}
          >
            <Card hidden={!loading}>
              <CardBody>
                <p>Logging In</p>
                <Spinner color="primary" />
              </CardBody>
            </Card>
          </div>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card
                style={{ opacity: loading ? "0.5" : "1" }}
                className="overflow-hidden"
              >
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Concize.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt="Concize logo"
                            className="rounded-circle"
                            height="50"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        setLoading(true)
                        handleValidSubmit(v)
                      }}
                    >
                      {error ? (
                        <Alert color="danger" toggle={handleAlertClose}>
                          {error}
                        </Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3 position-relative">
                        <AvField
                          name="password"
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          required
                          placeholder="Enter Password"
                        />
                        <div
                          className="cursor-pointer"
                          onClick={handlePassword}
                          style={{
                            position: "absolute",
                            right: "8px",
                            top: "55%",
                          }}
                        >
                          {showPassword ? (
                            <i className="far fa-eye-slash"></i>
                          ) : (
                            <i className="far fa-eye"></i>
                          )}
                        </div>
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={loading}
                        >
                          Log In
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                      <h5 className="font-size-14 mb-3">Sign in with</h5>

                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <FacebookLogin
                            appId={facebook.APP_ID}
                            autoLoad={false}
                            callback={facebookResponse}
                            render={renderProps => (
                              <Link
                                to="#"
                                className="social-list-item bg-primary text-white border-primary"
                                onClick={renderProps.onClick}
                              >
                                <i className="mdi mdi-facebook" />
                              </Link>
                            )}
                          />
                        </li>
                        <li className="list-inline-item">
                          <TwitterLogin
                            loginUrl={
                              "http://localhost:4000/api/v1/auth/twitter"
                            }
                            onSuccess={this.twitterResponse}
                            onFailure={this.onFailure}
                            requestTokenUrl={
                              "http://localhost:4000/api/v1/auth/twitter/revers"
                            }
                            showIcon={false}
                            tag={"div"}
                          >
                            <a
                              href=""
                              className="social-list-item bg-info text-white border-info"
                            >
                              <i className="mdi mdi-twitter" />
                            </a>
                          </TwitterLogin>
                        </li>
                        <li className="list-inline-item">
                          <GoogleLogin
                            clientId={google.CLIENT_ID}
                            render={renderProps => (
                              <Link
                                to="#"
                                className="social-list-item bg-danger text-white border-danger"
                                onClick={renderProps.onClick}
                              >
                                <i className="mdi mdi-google" />
                              </Link>
                            )}
                            onSuccess={googleResponse}
                            onFailure={() => {}}
                          />
                        </li>
                      </ul>
                    </div> */}

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-2 d-flex justify-content-center">
                <p className="me-2">Don't have an account ?</p>
                <Link to="register" className="fw-medium text-primary">
                  Signup now
                </Link>
              </div>
              <p className="text-center">
                © {new Date().getFullYear()} Concize
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NewLogin
