import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Form, DatePicker } from "antd"
import PostPreview from "./postPreview"
import PostForm from "./postForm"
import DeletePostModal from "./deletePostModal"
import moment from "moment"
import AWS from "aws-sdk"
import uuid from "react-uuid"
import toast from "react-hot-toast"

const PostModal = ({
  socialAccounts,
  showPostModal,
  data,
  editMode,
  handleClosePostModal,
  selectedDay,
  onSave,
  onUpdate,
  onDelete,
  handleLoadingState,
  campaigns,
}) => {
  const region = process.env.REACT_APP_AWS_S3_REGION
  const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY
  const secretAccessKey = process.env.REACT_APP_AWS_SECRET_KEY
  const bucketName = process.env.REACT_APP_AWS_S3_BUCKET_NAME

  const s3 = new AWS.S3({
    region,
    accessKeyId,
    secretAccessKey,
  })

  function keyGen(keyLength) {
    var i,
      key = "",
      characters =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"

    var charactersLength = characters.length

    for (i = 0; i < keyLength; i++) {
      key += characters.substr(
        Math.floor(Math.random() * charactersLength + 1),
        1
      )
    }

    return key
  }

  const [selectedAccounts, setSelectedAccounts] = useState(undefined)
  const [content, setContent] = useState(undefined)
  const [contentStr, setContentStr] = useState(undefined)
  const [scheduleDate, setScheduleDate] = useState(undefined)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [photos, setPhotos] = useState(undefined)
  const [videos, setVideos] = useState(undefined)
  const [files, setFiles] = useState(undefined)
  const [isMobileView, setIsMobileView] = useState(true)

  const handleAddPhotos = (mediaFiles, mediaType) => {
    if (mediaType === 1) {
      setPhotos(mediaFiles)
    } else {
      setVideos(mediaFiles)
    }
    setFiles(mediaFiles)
  }

  useEffect(() => {
    if (showPostModal) {
      console.log("editMode", editMode)
      if (editMode) {
        setSelectedAccounts(data.accountIDs)
        setContent(data.content)

        if (data.scheduleDate) {
          setScheduleDate(moment(data.scheduleDate))
        } else {
          setScheduleDate(undefined)
        }
      } else {
        setScheduleDate(moment())
        setSelectedAccounts(null)
        setContent(null)
      }
      setShowDeleteModal(false)
    }
  }, [showPostModal])

  useEffect(() => {
    if (selectedDay && !editMode) {
      setScheduleDate(moment(selectedDay.date))
    }
  }, [selectedDay, showPostModal])

  const handleSelectAccounts = event => {
    setSelectedAccounts(event)
  }

  const handleContentChange = (cotentHtml, contentTxt) => {
    setContent(cotentHtml)
    setContentStr(contentTxt)
  }

  const uploadMediaToS3Promises = files => {
    let promises = files.map(singleFile => {
      if (singleFile.path !== undefined && singleFile.path !== null) {
        let key = keyGen(10) + singleFile.path.replaceAll(" ", "")
        const params = {
          Bucket: "concize-local-medias",
          Key: key,
          Body: singleFile,
        }
        return s3.upload(params).promise()
      }
    })
    return promises
  }

  const uploadMediaToS3 = async (files, postInput) => {
    let awsMedias = []
    let promises = uploadMediaToS3Promises(files)
    const datas = await Promise.all(promises)
    console.log("datas", datas, files)
    files.forEach(file => {
      if (file.path === undefined || file.path === null) {
        awsMedias.push({
          id: file.id,
          key: file.key,
          name: file.name,
          type: file.type,
          preview: file.preview,
        })
      }
    })
    if (datas && datas.length === files.length) {
      datas.forEach((ele, index) => {
        if (ele) {
          let singleFile = files[index]
          awsMedias.push({
            id: uuid(),
            key: ele.key,
            name: singleFile.path,
            type: singleFile.type,
            preview: ele.Location,
          })
        }
      })
      postInput["awsMedias"] = awsMedias
    }

    return postInput
  }

  const checkIfValid = postInput => {
    let message = ""
    postInput.accountIDs.forEach(accountID => {
      let account = socialAccounts.find(acc => acc.id == accountID)

      if (account.accountType === "Instagram") {
        if (postInput.isTextPost) {
          message =
            "Instagram account does not provide text based post. Please select photos or videos"
        } else if (postInput.isPicturePost && photos.length <= 1) {
          message = "Instagram carousel post needs atleast 2 photos"
        } else {
          message = ""
        }
      }
    })
    return message
  }

  const onSubmit = async values => {
    console.log("data", data, selectedDay)
    handleLoadingState(true)
    handleClosePostModal()
    let postType
    let postInput = {
      campaignID: values.campaign,
      start: selectedDay.dateStr,
      accountIDs: values.accounts,
      subject: "Publish",
      content: contentStr,
      isPosted: false,
      isPicturePost: photos && photos.length ? true : false,
      isVideoPost: videos && videos.length ? true : false,
      isTextPost:
        (photos && photos.length) || (videos && videos.length) ? false : true,
    }
    console.log("postInput", postInput, checkIfValid(postInput))

    let msg = checkIfValid(postInput)
    if (msg !== "") {
      handleLoadingState(false)
      toast.error(msg)
      return
    }

    if (scheduleDate) {
      postInput = {
        ...postInput,
        scheduleDate: scheduleDate.toDate(),
      }
      postType = "schedule_post"
    } else {
      postType = "direct_post"
    }
    console.log("files", files)
    if (files && files.length) {
      // Upload to S3
      postInput = await uploadMediaToS3(files, postInput)
    }

    console.log("postInput", postInput)

    if (editMode) {
      onUpdate(postInput, data.id, postType)
    } else {
      onSave(postInput, postType)
    }
  }

  const handleDeletePost = () => {
    if (data) {
      setShowDeleteModal(true)
    }
  }

  const handleDeleteConfirm = () => {
    onDelete(data.id)
    handleClosePostModal()
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const handleSchedulePost = date => {
    if (date) {
      setScheduleDate(date)
    } else {
      setScheduleDate(undefined)
    }
  }

  return (
    <Modal
      className="modal-fullscreen widget-modal"
      size="xl"
      isOpen={showPostModal}
      toggle={handleClosePostModal}
    >
      <ModalHeader tag="h4" toggle={handleClosePostModal}>
        {editMode ? "Edit Post" : "Create Post"}
      </ModalHeader>
      <Form
        onFinish={onSubmit}
        initialValues={{
          ["accounts"]: data ? data.accountIDs : [],
          ["content"]: data ? data.content : undefined,
          ["campaign"]: data ? data.campaign?.id : null,
        }}
      >
        <ModalBody className="custom-modal-body">
          <div className="form-and-preview">
            <div className="left-form">
              <PostForm
                socialAccounts={socialAccounts}
                handleSelectAccounts={handleSelectAccounts}
                handleContentChange={handleContentChange}
                selectedAccounts={selectedAccounts}
                content={content}
                handleAddPhotos={handleAddPhotos}
                editMode={editMode}
                campaigns={campaigns}
                files={data && data.awsMedias}
              />
            </div>
            <div className="right-preview post-preview">
              <PostPreview
                mobile={isMobileView}
                mediaFiles={files}
                socialAccounts={socialAccounts}
                selectedAccounts={selectedAccounts}
                content={content}
                posts={data && data.socialPosts}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              <div>
                <DatePicker
                  value={scheduleDate}
                  showTime={{ format: "hh:mm A" }}
                  disabledDate={current =>
                    current.isBefore(moment().subtract(1, "day"))
                  }
                  placeholder="Schedule Post"
                  className="rounded-3"
                  format="MMM Do YYYY, hh:mm A"
                  // onOk={handleSchedulePost}
                  onOk={null}
                  onChange={handleSchedulePost}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div
                onClick={handleClosePostModal}
                className="btn btn-outline-secondary rounded-3 me-2"
              >
                Cancel
              </div>
              <div className="btn btn-outline-primary rounded-3 me-2">
                Save as Draft
              </div>
              <div
                hidden={!editMode}
                onClick={handleDeletePost}
                className="btn btn-danger rounded-3 me-2"
              >
                Delete Post
              </div>
              <button type="submit" className="btn btn-primary rounded-3">
                {editMode ? "Update post" : "Publish"}
              </button>
            </div>
          </div>
        </ModalFooter>
      </Form>
      <DeletePostModal
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleDeleteConfirm={handleDeleteConfirm}
      />
      <div className="mobile-or-pc">
        <div
          className={isMobileView ? "blue-bg me-2" : "me-2"}
          onClick={() => setIsMobileView(true)}
        >
          <i className="fal fa-mobile font-size-24"></i>
        </div>
        <div
          className={!isMobileView ? "blue-bg" : ""}
          onClick={() => setIsMobileView(false)}
        >
          <i className="fal fa-laptop font-size-20"></i>
        </div>
      </div>
    </Modal>
  )
}

export default PostModal
