import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Card, CardBody, Col, Container, Row, Spinner, Badge } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import AccountModal from "./components/accountModal"
import "./accounts.css"
import user1 from "../../assets/images/users/user.png"

import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import { ACCOUNTS } from "../../graphql/queries"
import {
  ADD_LINKEDIN,
  ADD_TWITTER,
  ADD_FACEBOOK,
  DELETE_ACCOUNT,
  ADD_INSTAGRAM,
} from "../../graphql/mutation"
import ConfirmDeleteModal from "components/Dialog/confirmDeleteModal"
import moment from "moment/moment"
import { useHistory } from "react-router-dom"
import { map } from "lodash"
import { Tooltip } from "antd"
import toast from "react-hot-toast"

const Accounts = () => {
  const [accounts, setAccounts] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [accountData, setAccountData] = useState(undefined)
  const history = useHistory()

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const { refetch } = useQuery(ACCOUNTS, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.accounts) {
        setAccounts(data.accounts)
      }
      let urlParameters = new URLSearchParams(window.location.search)
      console.log("window.location.href", window.location.href)
      if (
        urlParameters === null ||
        urlParameters === undefined ||
        urlParameters === ""
      ) {
        toast.error("Something went worng")
        return
      }

      let accountType = urlParameters.get("accountType")

      let code = urlParameters.get("code")

      if (accountType === "linkedinCompany") {
        // Linkedin
        handleAddLinkedIn(urlParameters)
      } else if (urlParameters.get("oauth_token")) {
        // Twitter
        handleAddTwitter(urlParameters)
      } else if (accountType === "facebookPages") {
        handleAddFacebook(urlParameters)
      } else if (accountType === "instagram") {
        handleAddInstagram(code)
      }

      /* if (
        urlParameters &&
        urlParameters.get("code") &&
        urlParameters.get("state")
      ) {
        handleAddLinkedIn(urlParameters)
      } else if (
        urlParameters.get("code") &&
        urlParameters.get("type") == "instagram"
      ) {
        handleAddInstagram(urlParameters.get("code"))
      } else if (
        urlParameters.get("code") &&
        window.location.href.includes("#_=_")
      ) {
        handleAddFacebook(urlParameters)
      } else if (urlParameters && urlParameters.get("oauth_token")) {
        handleAddTwitter(urlParameters)
      } */
    },
    onError: error => {
      console.log("error", error)
    },
  })

  const [addInstagram] = useMutation(ADD_INSTAGRAM, {
    onCompleted: data => {
      setLoading(false)
      if (data && data.addInstagram) {
        refetch()
      }
      history.replace({
        search: "",
      })
    },
    onError: error => {
      setLoading(false)
      console.log("error", error)
    },
  })

  const [addFacebook] = useMutation(ADD_FACEBOOK, {
    onCompleted: data => {
      setLoading(false)
      if (data && data.addFacebook) {
        let newAccount = data.addFacebook
        if (accounts && accounts.length) {
          let index = accounts.findIndex(ele => ele.id === newAccount.id)
          if (index !== undefined && index !== null && index > -1) {
            accounts[index] = newAccount
            setAccounts(accounts)
          } else {
            setAccounts([newAccount, ...accounts])
          }
        } else {
          setAccounts([newAccount])
        }
        history.replace({
          search: "",
        })
      }
    },
    onError: error => {
      setLoading(false)
      console.log("error", error)
    },
  })

  const [addLinkedin] = useMutation(ADD_LINKEDIN, {
    onCompleted: data => {
      setLoading(false)
      if (data && data.addLinkedin) {
        // let newAccount = data.addLinkedin
        // if (accounts && accounts.length) {
        //   setAccounts([newAccount, ...accounts])
        // } else {
        //   setAccounts([newAccount])
        // }
        refetch()
      }
      history.replace({
        search: "",
      })
    },
    onError: error => {
      setLoading(false)
      console.log("error", error)
    },
  })

  const [addTwitter] = useMutation(ADD_TWITTER, {
    onCompleted: data => {
      setLoading(false)
      if (data && data.addTwitter) {
        let newAccount = data.addTwitter
        if (accounts && accounts.length) {
          setAccounts([newAccount, ...accounts])
        } else {
          setAccounts([newAccount])
        }
      }
      history.replace({
        search: "",
      })
    },
    onError: error => {
      setLoading(false)
      console.log("error", error)
    },
  })

  const [deleteAccount] = useMutation(DELETE_ACCOUNT, {
    onCompleted: data => {
      if (data && data.deleteAccount) {
        let index = accounts.findIndex(acc => acc.id === accountData.id)
        if (index > -1) {
          setAccounts([
            ...accounts.slice(0, index),
            ...accounts.slice(index + 1),
          ])
        }
      }
      setAccountData(null)
      setShowDeleteModal(false)
    },
    onError: err => {
      console.log("err", err)
      toast.error(err?.message, {
        position: "top-right",
      })
    },
  })

  const handleAddInstagram = code => {
    setLoading(true)
    addInstagram({
      variables: {
        code,
      },
    })
  }

  const handleAddFacebook = urlParameters => {
    setLoading(true)
    let code = urlParameters.get("code")
    addFacebook({
      variables: {
        code,
      },
    })
  }

  const handleAddLinkedIn = urlParameters => {
    setLoading(true)
    let redirectUri = ""
    let type = ""
    console.log("urlParameters.get(type)", urlParameters.get("type"))
    if (urlParameters.get("type")) {
      redirectUri =
        process.env.REACT_APP_LINKEDIN_REDIRECT_URL + "?type=company"
      type = urlParameters.get("type")
    } else {
      redirectUri = process.env.REACT_APP_LINKEDIN_REDIRECT_URL
      type = "personal"
    }
    let request = {
      grantType: "authorization_code",
      code: urlParameters.get("code"),
      redirectUri,
      clientID: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
      clientSecret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
      accountType: "LinkedIn",
      isActive: true,
      type,
    }

    addLinkedin({
      variables: {
        input: request,
      },
    })
  }

  const handleAddTwitter = urlParameters => {
    setLoading(true)
    let request = {
      oAuthToken: urlParameters.get("oauth_token"),
      oAuthVerifier: urlParameters.get("oauth_verifier"),
    }

    addTwitter({
      variables: {
        input: request,
      },
    })
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleCreateAccount = () => {
    setShowModal(true)
  }

  const handleDeleteAccount = acc => {
    console.log("acc", acc)
    setShowDeleteModal(true)
    setAccountData(acc)
  }

  const { SearchBar } = Search

  const pageOptions = {
    sizePerPage: 5,
    totalSize: accounts.length,
    custom: true,
  }

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]

  const selectRow = {
    mode: "checkbox",
    style: { backgroundColor: "#fafafa" },
  }

  const accountsColumn = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
    },
    {
      text: "Social Name",
      dataField: "firstName",
      sort: true,
      formatter: (_, account) => {
        return (
          <div className="d-flex justify-content-start align-items-center">
            <img
              className="rounded-circle header-profile-user me-1"
              src={account.profilePicURL ? account.profilePicURL : user1}
              alt="Header Avatar"
            />
            <span>
              {account.firstName} {account.lastName}
            </span>
          </div>
        )
      },
    },
    {
      text: "Social Account",
      dataField: "accountType",
      sort: true,
      formatter: (_, account) => {
        let src = "https://img.icons8.com/color/48/null/linkedin.png"
        switch (account.accountType) {
          case "LinkedIn":
            src = "https://img.icons8.com/color/48/null/linkedin.png"
            break
          case "Twitter":
            src = "https://img.icons8.com/color/48/null/twitter--v1.png"
            break
          case "Facebook":
            src = "https://img.icons8.com/fluency/48/null/facebook-new.png"
            break
          case "Instagram":
            src = "https://img.icons8.com/fluency/48/null/instagram-new.png"
            break
          default:
            break
        }
        return (
          <div>
            <img className="me-1" width="24" src={src} />
            <span>{account.accountType}</span>
          </div>
        )
      },
    },
    {
      text: "Type",
      dataField: "type",
      sort: true,
      formatter: (_, account) => {
        let bg =
          account.type === "personal"
            ? "badge-soft-primary"
            : "badge-soft-warning"
        return (
          <Badge pill className={`${bg} me-1 font-size-12`}>
            {account.type}
          </Badge>
        )
      },
    },
    {
      text: "Pages",
      dataField: "pages",
      sort: false,
      formatter: (_, account) => {
        return (
          <div className="avatar-group">
            {map(account.pages, (page, index) =>
              page.img !== "" && page.img !== null ? (
                <Tooltip placement="top" title={page.name}>
                  <div className="avatar-group-item" key={index}>
                    <img
                      src={page.img}
                      className="rounded-circle avatar-xs"
                      alt=""
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip placement="top" title={page.name}>
                  <div className="avatar-xs avatar-group-item">
                    <span
                      className={
                        "avatar-title rounded-circle bg-" +
                        "primary" +
                        " text-white font-size-16"
                      }
                    >
                      {page.name.charAt(0)}
                    </span>
                  </div>
                </Tooltip>
              )
            )}
          </div>
        )
      },
    },
    {
      text: "Status",
      dataField: "isActive",
      sort: true,
      formatter: (_, account) => {
        let badge
        if (account.isActive) {
          badge = (
            <Badge pill className="badge-soft-success me-1 font-size-12">
              Active
            </Badge>
          )
        } else {
          badge = (
            <Badge pill className="badge-soft-secondary me-1 font-size-12">
              Inactive
            </Badge>
          )
        }
        return badge
      },
    },
    {
      text: "Owner",
      dataField: "owner",
      sort: true,
      formatter: (_, account) => {
        return (
          <div className="d-flex justify-content-start align-items-center">
            <div className="avatar-xs me-1">
              <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-14">
                {account?.user?.fName !== undefined
                  ? account?.user?.fName.charAt(0)
                  : "?"}
              </span>
            </div>
            <span>
              <div>
                {account.user.fName} {account.user.lName}
              </div>
            </span>
          </div>
        )
      },
    },
    {
      text: "Expiry Date",
      dataField: "expiryDate",
      sort: true,
      formatter: (_, account) => {
        if (account.accountType === "Twitter") {
          return "No Expiry"
        }
        let date = moment(account.expiryDate)
        return date.format("DD/MM/YYYY")
      },
    },
    {
      text: "Expiries In ",
      dataField: "expiriesIn",
      sort: true,
      formatter: (_, account) => {
        if (account.accountType === "Twitter") {
          return "No Expiry"
        }
        let date = moment(account.expiryDate).diff(moment(), "days")
        return `${date} Days`
      },
    },
    {
      text: "Following",
      dataField: "following",
      sort: true,
    },
    {
      text: "Posts",
      dataField: "posts",
      hidden: true,
      sort: true,
    },
    {
      text: "Groups",
      dataField: "groups",
      hidden: true,
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (_, account) => (
        <div className="d-flex gap-3">
          <div
            className="text-success d-none"
            onClick={() => {}}
            style={{ cursor: "pointer" }}
          >
            <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
          </div>
          <div
            className="text-danger"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleDeleteAccount(account)
            }}
          >
            <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
          </div>
        </div>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Accounts | Concize</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            left="Accounts"
            url="/settings"
            urlTitle="Settings"
            right="Accounts"
          />

          <Row>
            <Col lg="12">
              <Card style={{ position: "relative" }}>
                <CardBody>
                  <Card
                    style={{
                      position: "absolute",
                      zIndex: "2",
                      left: `calc(50% - 100px)`,
                      top: "30%",
                      width: "150px",
                    }}
                    className="shadow-lg text-center"
                  >
                    <CardBody hidden={!loading}>
                      <p>Please wait</p>
                      <Spinner color="primary" hidden={!loading} />
                    </CardBody>
                  </Card>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={accounts}
                    columns={accountsColumn}
                    keyField="name"
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="name"
                        data={accounts}
                        columns={accountsColumn}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end d-flex justify-content-end">
                                  <button
                                    className="font-16 btn-block btn btn-primary rounded-3 me-2"
                                    onClick={handleCreateAccount}
                                  >
                                    <i className="fas fa-plus-circle me-2" />
                                    Create Account
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    keyField="name"
                                    // selectRow={selectRow}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                    responsive
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="d-none">
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <AccountModal
          showModal={showModal}
          editMode={editMode}
          handleClose={handleCloseModal}
        />
        <ConfirmDeleteModal
          showDialog={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          onConfirm={() => {
            deleteAccount({
              variables: {
                accountID: accountData.id,
              },
            })
          }}
          title="Delete Account"
          subText={`Are you sure you want to delete "${accountData?.firstName} ${accountData?.accountType}" account?`}
        />
      </div>
    </React.Fragment>
  )
}

export default Accounts
