import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter, Redirect } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import store from "./store"
import jwt_decode from "jwt-decode"

import "firebase/auth"
import firebase from "firebase/app"

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
})

const getNewToken = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        // User is signed in.
        // Fetch new token
        let data = await user.getIdToken(true)
        let authUser = JSON.parse(localStorage.getItem("authUser"))
        authUser.stsTokenManager.accessToken = data
        localStorage.setItem("authUser", JSON.stringify(authUser))

        resolve(data)
      } else {
        // User is signed out.
        reject(null)
        return <Redirect to="/logout" />
      }
    })
  })
}

const validateToken = async token => {
  let { exp } = jwt_decode(token)
  let expirationTime = exp * 1000 - 60000
  let dateNow = Date.now()

  if (dateNow >= expirationTime) {
    let data = await getNewToken()
    return data
  }
  return token
}

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const obj = JSON.parse(localStorage.getItem("authUser"))

  if (obj === null) {
    return {
      headers: {
        ...headers,
      },
    }
  }
  let token = obj?.stsTokenManager?.accessToken

  console.log("token", token)
  // Check if token expired
  let newToken = await validateToken(token)
  console.log("newToken --->", newToken)

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: newToken ? `Bearer ${newToken}` : "",
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // link: mainLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getTask: {
            read: (_, { args, toReference }) => {
              return toReference({
                __typename: "Task",
                id: args.id,
              })
            },
          },
          getSubTask: {
            read: (_, { args, toReference }) => {
              return toReference({
                __typename: "SubTask",
                id: args.id,
              })
            },
          },
        },
      },
    },
  }),
})

const app = (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
