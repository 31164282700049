import React, { useEffect, useState } from "react"
import { Row, Col, Form, Input, Select } from "antd"
import IconButton from "@material-ui/core/IconButton"
import { ColorPicker } from "material-ui-color"
import toast from "react-hot-toast"

const { TextArea } = Input

const GroupCustomField = ({
  field,
  fieldIndex,
  defaultCustomFields,
  handleFieldName,
  handleFieldType,
  palette,
  handleColorPickerChange,
  editMode,
}) => {
  // console.log("field---->", field)

  const [optionsLen, setOptionsLen] = useState(0)

  useEffect(() => {
    if (field) {
      setOptionsLen(field?.options?.length)
    } else {
      setOptionsLen(0)
    }
  }, [])

  const handleFieldNameChange = event => {
    // setFieldName(event.target.value)
    handleFieldName(event.target.value, fieldIndex)
  }

  const handleFieldTypeChange = value => {
    handleFieldType(value, fieldIndex)
  }

  const handleColorPicker = (e, index) => {
    let newOptions = []

    field.options.forEach(ele => {
      newOptions.push({
        name: ele.name,
        color: ele.color,
      })
    })

    let obj = {
      ...newOptions[index],
      color: `#${e.hex}`,
    }
    newOptions[index] = obj

    field.show = true
    field.options = newOptions
    handleColorPickerChange(field, fieldIndex)
  }

  const handleDropdownOptionChange = (e, optionIndex) => {
    let value = e.target.value
    console.log("field --->", field)
    let newOptions = [...field.options]
    newOptions[optionIndex] = {
      name: value,
      color: newOptions[optionIndex].color,
    }
    field.show = true
    field.options = newOptions
    handleColorPickerChange(field, fieldIndex)
  }

  const handleAddDropdownOption = () => {
    let l = field.options.length
    if (field.options[l - 1].name === "") {
      return toast.error("Please enter option name to add more options", {
        position: "top-right",
      })
    }
    const newOptions = [
      ...field.options,
      {
        name: "",
        color: "#6FB2D2",
      },
    ]
    field.show = true
    field.options = newOptions
    handleColorPickerChange(field, fieldIndex)
  }

  const handleDropdownOptionRemove = optionIndex => {
    if (field.options.length > 1) {
      let newOptions = field.options.filter((_, i) => i !== optionIndex)

      field.show = true
      field.options = newOptions

      handleColorPickerChange(field, fieldIndex)
    } else {
      toast.error("A dropdown must have atlease one option.", {
        position: "top-right",
      })
    }
  }

  const handleDescriptionChange = event => {
    console.log("value", event.target.value)
    field.description = event.target.value
    handleColorPickerChange(field, fieldIndex)
  }

  return (
    <div>
      <Row gutter={26}>
        <Col span={12}>
          <span className="d-block text-secondary font-size-12 mb-1">
            Field name <span className="text-danger">*</span>
          </span>
          <Input
            onChange={handleFieldNameChange}
            placeholder="Enter field name"
            className="rounded-3"
            value={field?.fieldName}
          />
        </Col>
        <Col span={12}>
          <span className="d-block text-secondary font-size-12 mb-1">
            Field type <span className="text-danger">*</span>
          </span>
          <Select
            disabled={editMode && field.id !== undefined && field.id !== null}
            suffixIcon={<i className="fas fa-chevron-down"></i>}
            className="subtask-hover rounded-3"
            style={{
              height: "32px",
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              width: "100%",
            }}
            allowClear
            value={field && field.fieldType ? field.fieldType : null}
            onChange={handleFieldTypeChange}
            placeholder="Select a field type"
            bordered={false}
            dropdownStyle={{ borderRadius: "6px" }}
          >
            {defaultCustomFields &&
              defaultCustomFields.map((data, index) => {
                if (data.name === "Group") {
                  return null
                }
                return (
                  <Select.Option value={data.name} key={index}>
                    {data.name}
                  </Select.Option>
                )
              })}
          </Select>
          <small className="text-secondary font-size-10">
            Once you choose field type, you cannot change it.
          </small>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <span className="d-block text-secondary font-size-12 mb-1">
            Description
          </span>
          <TextArea
            className="mb-2"
            style={{
              borderRadius: "8px",
            }}
            onChange={handleDescriptionChange}
            value={field?.description}
            placeholder="Add description to this custom field"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Col>
      </Row>
      {field.fieldType.includes("Dropdown") && (editMode || field.show)
        ? field.options.map((option, optionIndex) => {
            console.log("optionsLen ---->", optionsLen)
            return (
              <div className="d-flex" key={optionIndex}>
                <div
                  className="subtask-hover"
                  style={{
                    width: "90%",
                  }}
                >
                  <Row className="customMargin pt-1 ps-1">
                    <Col span={24}>
                      <Input
                        value={option.name}
                        bordered={false}
                        onChange={e =>
                          handleDropdownOptionChange(e, optionIndex)
                        }
                        size="middle"
                        placeholder={`Option ${optionIndex + 1}`}
                        style={{
                          backgroundColor: "transparent",
                        }}
                        suffix={
                          <IconButton
                            disabled={editMode && optionIndex !== optionsLen}
                            onClick={() =>
                              handleDropdownOptionRemove(optionIndex)
                            }
                            size="small"
                          >
                            <i className="fal fa-times font-size-14"></i>
                          </IconButton>
                        }
                      ></Input>
                    </Col>
                  </Row>
                  <div className="custom-border mt-1"></div>
                </div>
                <ColorPicker
                  onChange={e => {
                    handleColorPicker(e, optionIndex)
                  }}
                  palette={palette}
                  value={option.color}
                  hideTextfield
                  key={optionIndex}
                />
              </div>
            )
          })
        : ""}
      {field.fieldType.includes("Dropdown") && (field.show || editMode) ? (
        <Row className="mt-2 ms-2">
          <Col>
            <div
              onClick={handleAddDropdownOption}
              className="customBtn p-1 px-2"
            >
              <i className="fal fa-plus me-2"></i>
              <span className="pe-1 font-size-12">Add an option</span>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </div>
  )
}

export default GroupCustomField
