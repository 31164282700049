import { useLazyQuery, useMutation } from "@apollo/client"
import { Checkbox, DatePicker, Input, Select } from "antd"
import TextArea from "antd/lib/input/TextArea"
const { Option } = Select
import React, { useState } from "react"
import { useEffect } from "react"
import { Modal, ModalBody, ModalHeader, Badge } from "reactstrap"
import { GET_GROUP_FIELD_VALUES } from "../../../graphql/queries"
import {
  ADD_GROUP_FIELD_VALUE,
  DELETE_GROUP_FIELD_VALUE,
  UPDATE_GROUP_FIELD_VALUE,
} from "../../../graphql/mutation"
import { Table } from "reactstrap"
import moment from "moment"

const GroupFieldValueModal = ({
  showGroupField,
  handleClose,
  selectedGroupField,
  taskID,
}) => {
  const [dataSource, setDataSource] = useState([])
  const user = JSON.parse(localStorage.getItem("concizeUser"))

  const [getGroupFieldValues] = useLazyQuery(GET_GROUP_FIELD_VALUES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      console.log("data", data)
      if (data && data.getGroupFieldValues) {
        setDataSource(data.getGroupFieldValues)
      } else {
        setDataSource(null)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [addGroupFieldValue] = useMutation(ADD_GROUP_FIELD_VALUE, {
    onCompleted: data => {
      console.log("data", data)
      if (data && data.addGroupFieldValue) {
        if (dataSource && dataSource.length) {
          setDataSource([data.addGroupFieldValue, ...dataSource])
        } else {
          setDataSource([data.addGroupFieldValue])
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [updateGroupFieldValue] = useMutation(UPDATE_GROUP_FIELD_VALUE, {
    onCompleted: data => {
      console.log("data", data)
      if (data && data.updateGroupFieldValue) {
        let index = dataSource.findIndex(
          ele => ele.id === data.updateGroupFieldValue.id
        )
        dataSource[index] = data.updateGroupFieldValue
        setDataSource([...dataSource])
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [deleteGroupFieldValue] = useMutation(DELETE_GROUP_FIELD_VALUE, {
    onCompleted: data => {
      console.log("data", data)
      if (data && data.deleteGroupFieldValue) {
        let index = dataSource.findIndex(
          ele => ele.id === data.deleteGroupFieldValue.id
        )
        dataSource.splice(index, 1)
        setDataSource([...dataSource])
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const handleAdd = () => {
    let values = []
    let input = {
      groupFieldID: selectedGroupField.id,
      taskID,
    }
    selectedGroupField.fields.forEach(ele => {
      values.push({
        fieldID: ele.id,
        // value: "",
      })
    })
    input.values = values
    console.log("input", input)
    addGroupFieldValue({
      variables: {
        input,
      },
    })
  }

  useEffect(() => {
    if (showGroupField) {
      if (taskID && selectedGroupField && selectedGroupField.id) {
        getGroupFieldValues({
          variables: {
            taskID,
            groupFieldID: selectedGroupField.id,
          },
        })
      }
    }
  }, [showGroupField])

  const handleDateChange = (dateStr, dataIndex) => {
    dataSource[dataIndex].date = dateStr
    // console.log("dataSource", dataSource)
    setDataSource([...dataSource])
  }

  const handleFieldChange = (data, rowValueIndex, dataIndex) => {
    dataSource[dataIndex].values[rowValueIndex].value = data
    setDataSource([...dataSource])
  }

  const handleEditRow = dataIndex => {
    if (dataSource[dataIndex]["isEditable"]) {
      dataSource[dataIndex]["isEditable"] = !dataSource[dataIndex]["isEditable"]
      onSave(dataIndex)
      return
    } else {
      dataSource[dataIndex]["isEditable"] = true
    }
    console.log("dataSource", dataSource)
    setDataSource([...dataSource])
  }

  const handleCloseEdit = dataIndex => {
    dataSource[dataIndex]["isEditable"] = false
    setDataSource([...dataSource])
  }

  const handleDelete = dataIndex => {
    let id = dataSource[dataIndex].id
    deleteGroupFieldValue({
      variables: {
        id,
      },
    })
  }

  const onSave = dataIndex => {
    let data = dataSource[dataIndex]

    let id = dataSource[dataIndex].id
    let inputValues = []
    data.values.forEach(ele => {
      inputValues.push({
        fieldID: ele.fieldID,
        value: ele.value,
      })
    })
    let input = {
      groupFieldID: data.groupFieldID,
      taskID: data.taskID,
      userID: data.userID,
      values: inputValues,
    }
    console.log("input", input)
    updateGroupFieldValue({
      variables: {
        id,
        input,
      },
    })
  }

  const handleEditGroupField = () => {
    console.log("edit")
  }

  return (
    <Modal isOpen={showGroupField} size="lg">
      <ModalHeader toggle={handleClose}>
        <div className="d-flex">
          <div>{selectedGroupField?.fieldName}</div>
          {/* <div
            onClick={handleEditGroupField}
            className="subtask-hover px-2 rounded-3 ms-2 cursor-pointer"
          >
            <i className="fas fa-ellipsis-v"></i>
          </div> */}
        </div>
      </ModalHeader>
      <ModalBody>
        <div
          className="btn btn-sm btn-primary mb-2 rounded-3"
          onClick={handleAdd}
        >
          Add
        </div>
        <div className="table-responsive">
          <Table
            // className="table-striped"
            style={{
              overflow: "auto",
            }}
          >
            <thead>
              <tr>
                <th>User</th>
                {selectedGroupField &&
                  selectedGroupField.fields &&
                  selectedGroupField.fields.map((field, fieldIndex) => {
                    return <th key={fieldIndex}>{field.fieldName}</th>
                  })}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dataSource &&
                dataSource.map((data, dataIndex) => {
                  console.log("data", data)
                  let userName = data.user.fName.concat(" ", data.user.lName)
                  if (data.user.isDeleted) {
                    userName += " (Deleted User)"
                  }
                  return (
                    <tr key={dataIndex}>
                      <td>
                        <div
                          style={{
                            width: "120px ",
                          }}
                        >
                          {/* {`${data.user.fName} ${data.user.lName}`} */}

                          <div className="demo-option-label-item d-flex align-items-center">
                            {data.user.profileURL ? (
                              <img
                                src={data.user.profileURL}
                                alt=""
                                width="36"
                                height="36"
                                className="rounded-circle"
                              />
                            ) : (
                              <div className="avatar-xs">
                                <span
                                  style={{
                                    backgroundColor: "red",
                                  }}
                                  className="avatar-title rounded-circle bg-soft bg-success text-success font-size-14"
                                >
                                  {data?.user?.fName
                                    ? data.user.fName.charAt(0)
                                    : "?"}
                                </span>
                              </div>
                            )}
                            <div className="ms-2 font-size-13">{userName}</div>
                          </div>
                        </div>
                      </td>
                      {data.values &&
                        data.values.map((rowValue, rowValueIndex) => {
                          {
                            /* console.log("rowValue", rowValue) */
                          }
                          let rowField = null
                          selectedGroupField.fields.forEach(ele => {
                            if (ele.id === rowValue.fieldID) {
                              rowField = ele
                            }
                          })
                          if (rowField === null) return
                          let field
                          switch (rowField.fieldType) {
                            case "Number": {
                              field = (
                                <Input
                                  style={{
                                    width: "150px",
                                  }}
                                  readOnly={data.isEditable ? false : true}
                                  bordered={data.isEditable ? true : false}
                                  value={rowValue.value}
                                  type="number"
                                  className="rounded-3"
                                  onChange={e => {
                                    handleFieldChange(
                                      e.target.value,
                                      rowValueIndex,
                                      dataIndex
                                    )
                                  }}
                                />
                              )
                              break
                            }
                            case "Dropdown": {
                              field = (
                                <Select
                                  // disabled={data.isEditable ? false : true}
                                  bordered={data.isEditable ? true : false}
                                  onChange={(_, option) => {
                                    if (data.isEditable) {
                                      handleFieldChange(
                                        option.value,
                                        rowValueIndex,
                                        dataIndex
                                      )
                                    }
                                  }}
                                  style={{
                                    width: "150px",
                                  }}
                                  size="middle"
                                  value={rowValue?.value}
                                  placeholder={rowField.fieldName}
                                >
                                  {rowField.options?.map((item, index) => {
                                    return (
                                      <Option value={String(index)} key={index}>
                                        <Badge
                                          style={{
                                            background: item.color,
                                            padding: "6px 4px",
                                            minWidth: "100px",
                                          }}
                                          className="font-size-12"
                                        >
                                          {item.name}
                                        </Badge>
                                      </Option>
                                    )
                                  })}
                                </Select>
                              )
                              break
                            }
                            case "Dropdown Multi": {
                              field = (
                                <Select
                                  // disabled={data.isEditable ? false : true}
                                  bordered={data.isEditable ? true : false}
                                  mode="multiple"
                                  size="middle"
                                  placeholder={rowField.fieldName}
                                  style={{ minWidth: "150px" }}
                                  value={rowValue.value ? rowValue.value : []}
                                  onChange={(_, options) => {
                                    let newValuesArr = []
                                    if (options && options.length) {
                                      options.forEach((ele, index) => {
                                        newValuesArr.push(ele.value)
                                      })
                                    }
                                    if (data.isEditable) {
                                      handleFieldChange(
                                        newValuesArr,
                                        rowValueIndex,
                                        dataIndex
                                      )
                                    }
                                  }}
                                >
                                  {rowField.options?.map((item, index) => {
                                    return (
                                      <Option value={String(index)} key={index}>
                                        <Badge
                                          style={{
                                            background: item.color,
                                            padding: "6px 4px",
                                            minWidth: "100px",
                                          }}
                                          className="font-size-12"
                                        >
                                          {item.name}
                                        </Badge>
                                      </Option>
                                    )
                                  })}
                                </Select>
                              )
                              break
                            }
                            case "Text": {
                              field = (
                                <Input
                                  style={{
                                    width: "150px",
                                  }}
                                  readOnly={data.isEditable ? false : true}
                                  bordered={data.isEditable ? true : false}
                                  value={rowValue.value}
                                  onChange={e => {
                                    handleFieldChange(
                                      e.target.value,
                                      rowValueIndex,
                                      dataIndex
                                    )
                                  }}
                                  className="rounded-3"
                                />
                              )
                              break
                            }
                            case "Text Area": {
                              field = (
                                <TextArea
                                  style={{
                                    width: "150px",
                                  }}
                                  readOnly={data.isEditable ? false : true}
                                  bordered={data.isEditable ? true : false}
                                  value={rowValue.value}
                                  autoSize={{ minRows: 1, maxRows: 5 }}
                                  onChange={e => {
                                    handleFieldChange(
                                      e.target.value,
                                      rowValueIndex,
                                      dataIndex
                                    )
                                  }}
                                  className="rounded-3"
                                />
                              )
                              break
                            }
                            case "Date": {
                              field = (
                                <DatePicker
                                  style={{
                                    width: "150px",
                                  }}
                                  // disabled={data.isEditable ? false : true}
                                  // readOnly={data.isEditable ? false : true}
                                  bordered={data.isEditable ? true : false}
                                  value={
                                    rowValue.value
                                      ? moment(rowValue.value)
                                      : null
                                  }
                                  onChange={(_, dateStr) => {
                                    if (data.isEditable) {
                                      handleFieldChange(
                                        dateStr,
                                        rowValueIndex,
                                        dataIndex
                                      )
                                    }
                                  }}
                                  className="rounded-3"
                                />
                              )
                              break
                            }
                            case "Checkbox": {
                              field = (
                                <Checkbox
                                  style={{
                                    width: "100px",
                                  }}
                                  onChange={e => {
                                    console.log(e.target.checked)
                                    handleFieldChange(
                                      e.target.checked.toString(),
                                      rowValueIndex,
                                      dataIndex
                                    )
                                  }}
                                  disabled={data.isEditable ? false : true}
                                  checked={
                                    rowValue.value
                                      ? rowValue.value === "true"
                                      : false
                                  }
                                />
                              )
                              break
                            }
                            default:
                              break
                          }
                          return <td>{field}</td>
                        })}
                      <td>
                        {user && user.id === data.user.id ? (
                          <div className="d-flex gap-3">
                            <div
                              className="text-success"
                              onClick={() => {
                                handleEditRow(dataIndex)
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {data.isEditable ? (
                                <i
                                  className="mdi mdi-check-bold font-size-18"
                                  id="edittooltip"
                                ></i>
                              ) : (
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                ></i>
                              )}
                            </div>
                            {data.isEditable ? (
                              <div
                                className="text-secondary cursor-pointer"
                                onClick={() => {
                                  handleCloseEdit(dataIndex)
                                }}
                              >
                                <i
                                  className="mdi mdi-close font-size-18"
                                  id="deletetooltip"
                                ></i>
                              </div>
                            ) : null}
                            {data.isEditable ? null : (
                              <div
                                className="text-danger"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleDelete(dataIndex)
                                }}
                              >
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                ></i>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default GroupFieldValueModal
