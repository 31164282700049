import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Badge, Spinner } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

import ConfirmDeleteModal from "../../components/Dialog/confirmDeleteModal"

import UserModal from "./components/userModal"
import { useMutation, useQuery } from "@apollo/client"

import {
  SUB_USERS,
  GET_CUSTOME_ROLES,
  ALLOCATION_PROFILES,
  WORKSPACES,
} from "../../graphql/queries"
import {
  CREATE_SUB_USER,
  CREATE_BULK_USERS,
  UPDATE_SUB_USER,
  DELETE_SUB_USER,
} from "../../graphql/mutation"

import { CSVReader, CSVDownloader } from "react-papaparse"
import CustomErrorHandlingModal from "../../components/Common/CustomErrorHandlingModal"
import { ROLE, ROLES_ARRAY } from "../../components/helpers/enums"
import { errorDataColumns } from "./components/errorDataColumns"
import NotAuthorizedPage from "components/Common/NotAuthorizedPage"
import toast from "react-hot-toast"

const buttonRef = React.createRef()

const Users = props => {
  const obj = JSON.parse(localStorage.getItem("concizeUser"))
  let role = obj.role

  const [showUserModal, setShowUserModal] = useState(false)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [users, setUsers] = useState([])
  const [customRoles, setCustomRoles] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentUser, setCurrentUser] = useState(undefined)
  const [editMode, setEditMode] = useState(false)

  // For bulk import
  const [errorDataObjs, setErrorDataObjs] = useState([])
  const [showCustomDialog, setShowCustomDialog] = useState(false)
  const [allocationProfileMap, setAllocationProfileMap] = useState(undefined)
  const [workspacesMap, setWorkspacesMap] = useState(undefined)

  const {} = useQuery(SUB_USERS, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.subUsers) {
        setUsers(data.subUsers)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })
  const {} = useQuery(GET_CUSTOME_ROLES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.customRoles) {
        setCustomRoles(data.customRoles)
      }
    },
  })

  const {} = useQuery(ALLOCATION_PROFILES, {
    onCompleted: data => {
      if (
        data &&
        data.allocationProfiles !== null &&
        data.allocationProfiles.length
      ) {
        let map = {}
        for (let ap of data.allocationProfiles) {
          map[ap.name] = ap.id
        }
        setAllocationProfileMap(map)
      }
    },
  })

  const {} = useQuery(WORKSPACES, {
    onCompleted: data => {
      if (data && data.workspaces.length) {
        let map = {}
        for (let workspace of data.workspaces) {
          map[workspace.name] = workspace.id
        }

        setWorkspacesMap(map)
      }
    },
  })

  // use mutations
  const [createSubUser] = useMutation(CREATE_SUB_USER, {
    onCompleted: data => {
      if (data && data.createSubUser) {
        if (users && users.length) {
          setUsers([...users, data.createSubUser])
        } else {
          setUsers([data.createSubUser])
        }
      }
      setLoading(false)
    },
    onError: err => {
      console.log("err", err)
      toast.error(err?.message, {
        position: "top-right",
      })
      setLoading(false)
    },
  })

  const [updateSubUser] = useMutation(UPDATE_SUB_USER, {
    onCompleted: data => {
      if (data && data.updateSubUser) {
        let index = users.findIndex(user => {
          return user.id === currentUser.id
        })
        setUsers([
          ...users.slice(0, index),
          data.updateSubUser,
          ...users.slice(index + 1),
        ])
      }
      setLoading(false)
    },
    onError: err => {
      console.log("err", err)
      setLoading(false)
    },
  })

  const [createBulkUsers] = useMutation(CREATE_BULK_USERS, {
    onCompleted: data => {
      if (data && data.createBulkUsers) {
        if (users && users.length) {
          setUsers([...users, ...data.createBulkUsers])
        } else {
          setUsers([...data.createBulkUsers])
        }
      }
      setLoading(false)
      if (errorDataObjs.length > 0) {
        setShowCustomDialog(true)
      }
    },
    onError: err => {
      console.log("err", err)
      setLoading(false)
    },
  })

  const [deleteSubUser] = useMutation(DELETE_SUB_USER, {
    onCompleted: data => {
      if (data && data.deleteSubUser) {
        let index = users.findIndex(user => {
          return user.id === currentUser.id
        })
        let newUsers = [...users]
        newUsers.splice(index, 1)
        setUsers(newUsers)
      }
      setLoading(false)
    },

    onError: err => {
      console.log("err", err)
      setLoading(false)
    },
  })

  const { SearchBar } = Search

  const pageOptions = {
    sizePerPage: 10,
    totalSize: users.length,
    custom: true,
  }

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]

  const selectRow = {
    mode: "checkbox",
    style: { backgroundColor: "#fafafa" },
  }

  const userListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      formatter: (cellContent, user) => <>{user.id}</>,
    },
    {
      dataField: "img",
      text: "Profile",
      formatter: (cellContent, user) => (
        <>
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle bg-success">
              {user.fName.charAt(0)}
            </span>
          </div>
        </>
      ),
    },
    {
      text: "Name",
      dataField: "fName",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {`${user.fName} ${user.lName}`}
            </Link>
          </h5>
          {/* <p className="text-muted mb-0">{user.designation}</p> */}
        </>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "mobile",
      text: "Mobile",
      sort: true,
    },
    {
      text: "Status",
      dataField: "isActive",
      sort: true,
      formatter: (cellContent, user) =>
        user.isActive ? (
          <Badge pill className="badge-soft-success me-1 font-size-12">
            Active
          </Badge>
        ) : (
          <Badge pill className="badge-soft-secondary me-1 font-size-12">
            Inactive
          </Badge>
        ),
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
    },
    {
      dataField: "workspaces",
      text: "Workspaces",
      sort: true,
      formatter: (cellContent, user) => {
        let workspaces = []
        if (!user.workspaces || user.workspaces === null) {
          return ""
        }
        user.workspaces.forEach(workspace => {
          workspaces.push(
            <span
              key={workspace.id}
              className="badge badge-soft-primary font-size-12 me-1"
            >
              {workspace.name}
            </span>
          )
        })
        return <span>{workspaces}</span>
      },
    },
    {
      dataField: "allocationProfiles",
      text: "Allocation Profiles",
      sort: true,
      formatter: (cellContent, user) => {
        let allocationProfiles = []
        if (!user.allocationProfiles || user.allocationProfiles === null) {
          return ""
        }
        user.allocationProfiles.forEach(allocationProfile => {
          allocationProfiles.push(
            <span
              key={allocationProfile.id}
              className="badge badge-soft-primary font-size-12 me-1"
            >
              {allocationProfile.name}
            </span>
          )
        })
        return <span>{allocationProfiles}</span>
      },
    },
    {
      dataField: "lastLoggedIn",
      text: "Last Logged In",
      sort: true,
    },
    {
      dataField: "userGroup",
      text: "Groups",
      sort: true,
    },
    {
      dataField: "designation",
      text: "Designation",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <div
            className="text-success"
            onClick={() => handleUpdateUser(user)}
            style={{ cursor: "pointer" }}
          >
            <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
          </div>
          <div
            className="text-danger"
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteUser(user)}
          >
            <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
          </div>
        </div>
      ),
    },
  ]

  const handleUserModalClose = () => {
    setShowUserModal(false)
  }
  const handleDeletModalClose = () => {
    setShowConfirmDeleteModal(false)
  }

  const handleCreateNewUser = () => {
    setCurrentUser(undefined)
    setEditMode(false)
    setShowUserModal(true)
  }

  const handleUpdateUser = user => {
    setCurrentUser(user)
    setEditMode(true)
    setShowUserModal(true)
  }

  const handleDeleteUser = user => {
    setCurrentUser(user)
    setShowConfirmDeleteModal(true)
  }

  const onUserSave = userInput => {
    setLoading(true)

    createSubUser({
      variables: {
        input: userInput,
      },
    })

    setShowUserModal(false)
  }

  const onUserUpdate = (userInput, id) => {
    updateSubUser({
      variables: {
        id,
        input: userInput,
      },
    })
    setShowUserModal(false)
  }

  const onUserDelete = () => {
    setLoading(true)
    deleteSubUser({
      variables: {
        id: currentUser.id,
      },
    })
    setShowConfirmDeleteModal(false)
  }

  // handle upload file error
  const handleOnError = err => {
    console.log("err", err)
  }

  // Validate email
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  function isString(x) {
    return typeof x === "string"
  }

  // TODO
  function hasAllocationProfile(role, data) {
    if (role === ROLE.spaceUser && data !== "") {
      return true
    }
    return false
  }

  function hasWorkspace(role, data) {
    if (role === ROLE.spaceAdmin && data !== "") {
      return true
    }
    return false
  }

  function isAdmin(role) {
    if (role === ROLE.systemAdmin || role === ROLE.globalAdmin) {
      return true
    }
    return false
  }

  // handle file upload
  const handleOnFileLoad = datas => {
    setLoading(true)

    console.log("datas", datas)

    let errorDataObjs = []
    let subUserInputs = []

    // datas.forEach((ele, index) =>
    for (let index = 0; index < datas.length; index++) {
      let allocationProfileArr = null
      let workspaceArr = null

      let ele = datas[index]

      if (index > 0) {
        let e = ele.data
        if (e.length !== 10) {
          continue
        }
        if (
          isString(e[0]) && // first name
          isString(e[1]) && // last name
          validateEmail(e[2]) && // email validate
          ROLES_ARRAY.includes(e[3]) && // check if roles present
          isString(e[4]) && // designation
          isString(e[5]) && // department
          /^\d+$/.test(e[6]) // check if mobile number contains only int
          // e[7] is employee id, can be olny alphabets, only numbers, or both, or any char
        ) {
          if (hasAllocationProfile(e[3], e[8])) {
            let data = e[8]
            allocationProfileArr = []
            workspaceArr = null
            let arr = data.split(", ")
            for (let a of arr) {
              if (allocationProfileMap[a]) {
                allocationProfileArr.push(allocationProfileMap[a])
              }
            }
          } else if (hasWorkspace(e[3], e[9])) {
            let data = e[9]
            allocationProfileArr = null
            workspaceArr = []
            let arr = data.split(", ")
            for (let a of arr) {
              if (workspacesMap[a]) {
                workspaceArr.push(workspacesMap[a])
              }
            }
          } else if (isAdmin(e[3])) {
            allocationProfileArr = null
            workspaceArr = null
          } else {
            // ERROR
            let subUserInput = {
              First_Name: e[0],
              Last_Name: e[1],
              Email: e[2],
              Role: e[3],
              Designation: e[4],
              Department: e[5],
              Mobile: e[6],
              Employee_ID: e[7],
              Allocation_Profiles: e[8],
              Workspaces: e[9],
            }
            errorDataObjs.push(subUserInput)
            continue
          }

          let subUserInput = {
            fName: e[0],
            lName: e[1],
            email: e[2],
            role: e[3],
            designation: e[4],
            department: e[5],
            mobile: e[6],
            employeeID: e[7],
            allocationProfileIDs: allocationProfileArr,
            workspaceIDs: workspaceArr,
            isActive: true,
            isSubUser: true,
          }
          subUserInputs.push(subUserInput)
        } else {
          allocationProfileArr = null
          workspaceArr = null
          let subUserInput = {
            First_Name: e[0],
            Last_Name: e[1],
            Email: e[2],
            Role: e[3],
            Designation: e[4],
            Department: e[5],
            Mobile: e[6],
            Employee_ID: e[7],
            Allocation_Profiles: e[8],
            Workspaces: e[9],
          }
          errorDataObjs.push(subUserInput)
        }
      }
    }

    console.log("subUserInputs", subUserInputs)
    console.log("errorDataObjs", errorDataObjs)

    setErrorDataObjs(errorDataObjs)

    if (subUserInputs.length > 0) {
      createBulkUsers({
        variables: {
          input: subUserInputs,
        },
      })
    }
  }

  let config = {
    header: false,
  }

  return role === ROLE.spaceAdmin || role === ROLE.spaceUser ? (
    <NotAuthorizedPage />
  ) : (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          overflow: "scroll",
        }}
      >
        <MetaTags>
          <title>Users | Concize</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div>
              <h4 className="mb-sm-0 font-size-18">
                Users List
                <CSVDownloader
                  data={[
                    {
                      "First Name": "",
                      "Last Name": "",
                      Email: "",
                      Role: "",
                      Designation: "",
                      Department: "",
                      Mobile: "",
                      "Employee ID": "",
                      "Allocation Profiles": "",
                      Workspaces: "",
                    },
                  ]}
                  type="button"
                  filename={"bulk_import_users_template"}
                  bom={true}
                  className="btn btn-sm btn-outline-primary ms-2 rounded-3 d-none"
                >
                  <i className="fas fa-download me-2"></i>
                  Download Template
                </CSVDownloader>
              </h4>
            </div>
            {/* Render Breadcrumbs */}
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="/settings">Settings</Link>
                </li>
                <li className="breadcrumb-item active">Users List</li>
              </ol>
            </div>
          </div>

          <Row>
            <Col lg="12">
              <Card style={{ position: "relative" }}>
                <CardBody>
                  <Card
                    style={{
                      position: "absolute",
                      zIndex: "2",
                      left: `calc(50% - 100px)`,
                      top: "30%",
                      width: "150px",
                    }}
                    className="shadow-lg text-center"
                  >
                    <CardBody hidden={!loading}>
                      <p>Please wait</p>
                      <Spinner color="primary" hidden={!loading} />
                    </CardBody>
                  </Card>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    columns={userListColumns}
                    data={users}
                    keyField="fName"
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="fName"
                        data={users}
                        columns={userListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end d-flex justify-content-end">
                                  <button
                                    className="font-16 btn-block btn btn-primary rounded-3"
                                    onClick={handleCreateNewUser}
                                  >
                                    <i className="fas fa-plus-circle me-2" />
                                    Create New User
                                  </button>
                                  <CSVReader
                                    ref={buttonRef}
                                    onFileLoad={handleOnFileLoad}
                                    onError={handleOnError}
                                    noProgressBar
                                    config={config}
                                  >
                                    {({ file }) => (
                                      <div className="d-none">
                                        <button
                                          onClick={e => {
                                            if (buttonRef.current) {
                                              buttonRef.current.open(e)
                                            }
                                          }}
                                          className="font-16 btn-block btn btn-primary rounded-3 ms-2"
                                        >
                                          <i className="fas fa-file-import me-2" />
                                          Bulk Import User
                                        </button>
                                      </div>
                                    )}
                                  </CSVReader>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    keyField="fName"
                                    // selectRow={selectRow}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                    responsive
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 mt-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* User Modal */}
          <UserModal
            showModal={showUserModal}
            handleClose={handleUserModalClose}
            data={currentUser}
            customRoles={customRoles}
            editMode={editMode}
            onUpdate={onUserUpdate}
            onSave={onUserSave}
          />
          {/* Custom Dialog for bulk import user information */}
          <CustomErrorHandlingModal
            showCustomDialog={showCustomDialog}
            setShowCustomDialog={setShowCustomDialog}
            errorDataObjs={errorDataObjs}
            errorDataColumns={errorDataColumns}
            title="Bulk import user details"
            subText="These are the rows that are not added to the users table
          Download the unsuccessfull data. Fix the values in red mark. And
          upload again."
          />

          {/* ConfirmDeleteModal */}
          <ConfirmDeleteModal
            showDialog={showConfirmDeleteModal}
            handleClose={handleDeletModalClose}
            onConfirm={onUserDelete}
            title={`Delete User`}
            subText={`Are you sure you want to delete the user "${currentUser?.fName}"?`}
          ></ConfirmDeleteModal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Users
