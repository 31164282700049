import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import MetaTags from "react-meta-tags"

import { Container } from "reactstrap"
import { Row, Col, Menu, Dropdown, Switch, message } from "antd"
import toast, { Toaster } from "react-hot-toast"
import { useMutation, useLazyQuery, useQuery } from "@apollo/client"
import {
  TASKS,
  GET_TASKS_BY_USER_ID,
  GET_CUSTOM_FIELDS_BY_SPACE_ID,
  GET_TASK_FILTER,
  TASKS_PAGINATED,
  GET_ALL_USERS_BY_TENANT_ID,
  USERS_ACCESS_BY_TENANT_ID,
  GET_TASK,
  GET_COLUMN_ORDER,
} from "../../graphql/queries"
import {
  ADD_TASK,
  ADD_CUSTOM_FIELD,
  EDIT_CUSTOM_FIELD,
  ADD_TASK_FILTER,
  UPDATE_TASK_FILTER,
  DELETE_TASK_FILTER,
  CHANGE_TASK_COLUMN_ORDER,
} from "../../graphql/mutation"

import TaskTable from "./components/taskTable"
import RightDrawer from "./components/rightDrawer"
import TaskCustomFieldModal from "./components/addCustomField/taskCustomFieldModal"
import { Input } from "reactstrap"
import TaskFilterModal from "./components/filters/filterModal"
import { useDebounce } from "use-debounce/lib"
import NotAuthorizedPage from "components/Common/NotAuthorizedPage"

const TaskList = () => {
  const params = useParams()
  const history = useHistory()
  // const spaceID = params.id ? params.id : ""
  const workspaceID = params.workspaceID ? params.workspaceID : null
  const [tasks, setTasks] = useState(undefined)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [mainTaskID, setMainTaskID] = useState(undefined)
  const [selectedTask, setSelectedTask] = useState(undefined)
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(undefined)
  const [column, setColumn] = useState(undefined)
  const [customField, setCustomField] = useState(undefined)
  const [showCustomFieldModal, setShowCustomFieldModal] = useState(false)
  const [editCustomFieldMode, setEditCustomFieldMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [taskFilter, setTaskFilter] = useState(null)
  const queryparams = new URLSearchParams(location.search)
  const taskID = queryparams.get("taskID") ? queryparams.get("taskID") : ""
  const [totalTasksCount, setTotalTasksCount] = useState(0)
  const [users, setUsers] = useState(null)
  const [authorizedUser, setAuthorizedUser] = useState(true)
  let concizeUser = JSON.parse(localStorage.getItem("concizeUser"))
  const [spaceID, setSpaceID] = useState(null)
  const [columnOrder,setColumnOrder]= useState(undefined)

  const defaultTaskInput = {
    spaceID,
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    status: "",
    isSubtask: false,
    customFieldValues: [],
  }
  const defaultCustomFields = [
    { name: "Date", icon: "fal fa-calendar me-2" },
    { name: "Dropdown", icon: "fal fa-chevron-circle-down me-2" },
    { name: "Dropdown Multi", icon: "fal fa-chevron-circle-down me-2" },
    { name: "Text", icon: "fal fa-text me-2" },
    { name: "Text Area", icon: "fal fa-paragraph me-2" },
    { name: "Number", icon: "fal fa-sort-numeric-up-alt me-2" },
    { name: "Checkbox", icon: "fal fa-check-square me-2" },
    { name: "Group", icon: "fal fa-layer-group me-2" },
  ]

  // Filters
  const [showTaskFilter, setShowTaskFilter] = useState(false)
  const [filteredTasks, setFilteredTasks] = useState(null)
  const [searchText, setSearchText] = useState("")
  const [debouncedSearchText] = useDebounce(searchText, 500)
  const [isTaskName, setIsTaskName] = useState(true)
  const [isTaskDesc, setIsTaskDesc] = useState(false)

  const [customFields, setCustomFields] = useState(undefined)
  let hash = window.location.hash
  const [taskInput, setTaskInput] = useState(defaultTaskInput)

  // SECTION APIS
  const {} = useQuery(GET_ALL_USERS_BY_TENANT_ID, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.getUsers) {
        setUsers(data.getUsers)
      } else {
        setUsers(null)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [addTaskFilter] = useMutation(ADD_TASK_FILTER, {
    onCompleted: data => {
      if (data && data.addTaskFilter) {
        setTasks(data.addTaskFilter.tasks)
        setTaskFilter(data.addTaskFilter.filter)
        setTotalTasksCount(data.addTaskFilter.totalLength)
      }
      setShowTaskFilter(false)
      setLoading(false)
    },
    onError: err => {
      console.log("err", err)
      setTotalTasksCount(0)
      setShowTaskFilter(false)
      setLoading(false)
    },
  })
  const [getTask] = useLazyQuery(GET_TASK, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.getTask && tasks) {
        if (!(tasks.findIndex(t => t.id === data.getTask.id) > -1)) {
          setTasks([data.getTask,...tasks])
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })
  const [getColumnOrder] = useLazyQuery(GET_COLUMN_ORDER, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "standby",
    onCompleted: data => {
      if( data && data.getColumnOrders ){
        setColumnOrder(data.getColumnOrders)
      }
     
    },
    onError: err => {
      console.log("err", err)
    },
  })
  const [updateTaskFilter] = useMutation(UPDATE_TASK_FILTER, {
    onCompleted: data => {
      if (data && data.updateTaskFilter) {
        setTasks(data.updateTaskFilter.tasks)
        setTaskFilter(data.updateTaskFilter.filter)
        setTotalTasksCount(data.updateTaskFilter.totalLength)
      }
      setShowTaskFilter(false)
      setLoading(false)
    },
    onError: err => {
      console.log("err", err)
      setTotalTasksCount(0)
      setShowTaskFilter(false)
      setLoading(false)
    },
  })

  const [getTasksPaginated] = useLazyQuery(TASKS_PAGINATED, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "standby",
    errorPolicy: "all",
    onCompleted: data => {
      if (data && data.tasksPaginated && data.tasksPaginated.tasks) {
        if (data.tasksPaginated.after !== null) {
          //To remove duplicates
          let tasksPaginates = [...tasks, ...data.tasksPaginated.tasks]
          tasksPaginates = tasksPaginates.filter((value,index,array)=>array.findLastIndex(element=>(element.id===value.id))===index)
         
          setTasks(tasksPaginates)
          setTotalTasksCount(data.tasksPaginated.totalLength)
        } else {
          setTotalTasksCount(data.tasksPaginated.totalLength)
          setTasks([...data.tasksPaginated.tasks])
        }
      } else {
        setTasks(null)
        setTotalTasksCount(0)
        setTaskFilter(null)
      }
      if(taskID !== "" &&
      taskID !== null &&
      taskID !== undefined &&
      !(tasks?.findIndex(t => t.id === taskID) > -1)) {
        getTask({
          variables: {
            id: taskID,
          },
        })
      }
      setTaskFilter(data?.tasksPaginated?.filter)
      setLoading(false)
    },
    onError: err => {
      setLoading(false)
      setTasks(null)
      setTotalTasksCount(0)
    },
  })

  const [deleteTaskFilter] = useMutation(DELETE_TASK_FILTER, {
    onCompleted: data => {
      if (data && data.deleteTaskFilter) {
        setTaskFilter(null)
        setTasks(data.deleteTaskFilter.tasks)
        setTotalTasksCount(data.deleteTaskFilter.totalLength)
      }
      setShowTaskFilter(false)
      setLoading(false)
    },
    onError: err => {
      console.log("err", err)
      setTotalTasksCount(0)
      setShowTaskFilter(false)
      setLoading(false)
    },
  })

  const [getMyTasks] = useLazyQuery(GET_TASKS_BY_USER_ID, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "standby",
    onCompleted: data => {
      if (data && data.getTasksByUserID) {
        let userTasks = data.getTasksByUserID
        setTasks(userTasks)
        let IstasksAssignee = userTasks.find(task => task.id === taskID)
        if (!IstasksAssignee) {
          if (params.spaceID) {
            history.replace(
              `/${workspaceID}/space-list/${params.spaceID}?taskID=${taskID}`
            )
            setSpaceID(params.spaceID)
          }
        }
      } else {
        if (params.spaceID) {
          history.replace(
            `/${workspaceID}/space-list/${params.spaceID}?taskID=${taskID}`
          )
          setSpaceID(params.spaceID)
        }
        setTasks(null)
      }
      setLoading(false)
    },
    onError: err => {
      console.log("err", err)
      setLoading(false)
    },
  })

  const [addTask] = useMutation(ADD_TASK, {
    onCompleted: data => {
      if (data && data.addTask) {
        if (tasks && tasks.length) {
          setTasks([data.addTask, ...tasks])
          if (openDrawer) {
            addQueryParams("taskID", data.addTask?.id)
            addQueryParams("taskName", data.addTask?.title)
          }
        } else {
          setTasks([data.addTask])
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  const [getCustomFields] = useLazyQuery(GET_CUSTOM_FIELDS_BY_SPACE_ID, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "standby",
    onCompleted: data => {
      if (data && data.getCustomFields) {
        let newTaskInput = { ...taskInput }

        data.getCustomFields.forEach(ele => {
          newTaskInput.customFieldValues.push({
            custFieldID: ele.id,
            value: "",
          })
        })
        setTaskInput(newTaskInput)

        setCustomFields(data.getCustomFields)
      } else {
        setCustomFields(undefined)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })
  const handleColumnOrderChange = customField =>{
    if(columnOrder && columnOrder.length){
          let newColumnIDs = [...columnOrder]
          let actionIndex = newColumnIDs.findIndex(
          col => col === "7" 
        )
        newColumnIDs.splice(actionIndex, 0, customField.id)
        changeTaskColumnOrder({
          variables: {
          spaceID,
          columnOrderIDs: newColumnIDs,
        },
      }) 
      }
  }
 const [changeTaskColumnOrder] = useMutation(CHANGE_TASK_COLUMN_ORDER, {
  onCompleted: data => {
    if(data && data.changeColumnOrders ) {
      setColumnOrder(data.changeColumnOrders.columnOrderIDs)
    }
  },
  onError: err => {
    console.log("err", err)
  },
})

  const [taskAccessByWorkspace] = useLazyQuery(USERS_ACCESS_BY_TENANT_ID, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data.userTaskAccess !== null) {
        if (!data.userTaskAccess) {
          setAuthorizedUser(false)
        } else {
          setAuthorizedUser(true)
          getTasksPaginated({
            variables: {
              spaceID,
            },
          })
          getCustomFields({
            variables: {
              spaceID,
            },
          })
        }
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  useEffect(() => {
    setTasks(null)
    setTotalTasksCount(0)
    if (spaceID === "" && workspaceID) {
      getMyTasks({
        variables: {
          workspaceID,
        },
      })
    }
    setLoading(true)
  }, [spaceID, workspaceID])

  useEffect(() => {
    if (workspaceID && spaceID) {
      getColumnOrder({
        variables: {
          spaceID,
        },
      })
      taskAccessByWorkspace({
        fetchPolicy: "network-only",
        variables: {
          workspaceID,
          spaceID,
        },
      })
    }
  }, [workspaceID, spaceID])

  useEffect(() => {
    setTasks(null)
    setTotalTasksCount(0)
    if (params.id === null || params.id === undefined) {
      setSpaceID("")
    } else {
      setSpaceID(params.id)
    }
  }, [params.id])

  const loadMoreTasks = () => {
    setLoading(true)
    console.debug("tasks.length", tasks.length);
    if (tasks && tasks.length) {
      let lastTaskIndex = tasks.length - 1
      let lastTaskID = tasks[lastTaskIndex].id
      if (lastTaskID && spaceID) {
        getTasksPaginated({
          variables: {
            spaceID,
            after: lastTaskID,
          },
        })
      }
    }
  }

  const handleAddTaskFilter = taskFilterInput => {
    if (spaceID && workspaceID) {
      taskFilterInput.spaceID = spaceID
      taskFilterInput.workspaceID = workspaceID
      setLoading(true)
      addTaskFilter({
        variables: {
          input: taskFilterInput,
        },
      })
    }
  }
  const handleUpdateTaskFilter = taskFilterInput => {
    if (spaceID && workspaceID && taskFilter.id) {
      taskFilterInput.spaceID = spaceID
      taskFilterInput.workspaceID = workspaceID
      taskFilterInput.filters.forEach(filter => delete filter.__typename)
      setLoading(true)
      updateTaskFilter({
        variables: {
          id: taskFilter.id,
          input: taskFilterInput,
        },
      })
    }
  }

  const handleDeleteTaskFilter = id => {
    if (id) {
      setLoading(true)
      deleteTaskFilter({
        variables: {
          id,
        },
      })
    }
  }

  const handleAppendToCustomFields = newCustomField => {
    let newCustomFields
    let newTaskInput = { ...taskInput }
    if (customFields && customFields.length) {
      newCustomFields = [...customFields]
      newCustomFields.push(newCustomField)
    } else {
      newCustomFields = [newCustomField]
    }

    newTaskInput.customFieldValues.push({
      custFieldID: newCustomField.id,
      value: "",
    })
    setTaskInput(newTaskInput)

    setCustomFields(newCustomFields)

    addNewCustomFieldToTasks(newCustomField)
  }

  const handleUpdateCustomFieldsArr = editedCustomField => {
    let index = customFields.findIndex(c => c.id === editedCustomField.id)
    setCustomFields([
      ...customFields.slice(0, index),
      { ...editedCustomField },
      ...customFields.slice(index + 1),
    ])
  }

  const handleDeleteCustomField = index => {
    if (index > -1) {
      customFields.slice(index, 1)
      setCustomFields(customFields)
    }
  }

  const addNewCustomFieldToTasks = newCustomField => {
    if (tasks && tasks.length) {
      let newTasks = []
      tasks.forEach(ele => {
        var task = { ...ele }
        var taskCustomFieldValues = [...task.customFieldValues]
        taskCustomFieldValues.push({
          __typename: "CustomFieldValue",
          custFieldID: newCustomField.id,
          value: "",
        })
        task.customFieldValues = taskCustomFieldValues
        newTasks.push(task)
      })
      setTasks(newTasks)
    }

    toast.success(
      `Successfully added "${newCustomField.fieldName}" custom field to tasks`,
      {
        position: "top-right",
      }
    )
  }

  const addQueryParams = (paramsName, paramsValue) => {
    let searchParams = new URLSearchParams(location.search)
    searchParams.set(paramsName, paramsValue)
    history.push({
      search: searchParams.toString(),
    })
  }
  const removeQueryParams = params => {
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.has(params)) {
      queryParams.delete(params)
      history.replace({
        search: queryParams.toString(),
      })
    }
  }
  useEffect(() => {
    setOpenDrawer(false)
    setSelectedTask(undefined)
    setSelectedTaskIndex(undefined)
    setMainTaskID(undefined)
    setCustomFields(undefined)
    setTaskInput(defaultTaskInput)
  }, [spaceID])

  useEffect(() => {
    if (
      taskID !== "" &&
      taskID !== null &&
      taskID !== undefined &&
      tasks &&
      tasks.length > 0 &&
      tasks.findIndex(t => t.id === taskID) > -1
    ) {
      setOpenDrawer(true)
      setSelectedTask(tasks.find(task => task.id === taskID))
      setSelectedTaskIndex(tasks.findIndex(task => task.id === taskID))
      setMainTaskID(taskID)
    }
    if (
      taskID !== "" &&
      taskID !== null &&
      taskID !== undefined &&
      tasks &&
      !(tasks.findIndex(t => t.id === taskID) > -1)
    ) {
      getTask({
        variables: {
          id: taskID,
        },
      })
    }
  }, [taskID])
  useEffect(() => {
    if (hash && hash !== "") {
      document.getElementById(hash.replace("#", ""))?.scrollIntoView(true)
    }
  }, [hash])

  const handleCloseDrawer = () => {
    if (spaceID === "" && spaceID !== null) {
      setCustomFields(null)
    }
    removeQueryParams("taskID")
    removeQueryParams("taskName")
    setOpenDrawer(false)
    setSelectedTask(null)
  }

  const handleOpenDrawer = (selectedTask, selectedTaskIndex) => {
    if (!openDrawer) setOpenDrawer(true)
    if (taskID !== selectedTask?.id && selectedTask.space) {
      if (tasks && !spaceID) {
        window.history.replaceState(
          null,
          "Task list",
          `/${workspaceID}/space/${selectedTask?.space?.id}?taskID=${selectedTask?.id}`
        )
        addQueryParams("taskName", selectedTask?.title)
      }
    }
    if (spaceID && taskID !== selectedTask?.id) {
      addQueryParams("taskID", selectedTask?.id)
      addQueryParams("taskName", selectedTask?.title)
    }
    setSelectedTask(selectedTask)
    setSelectedTaskIndex(selectedTaskIndex)
    setMainTaskID(selectedTask.id)
    if (
      spaceID === "" &&
      spaceID !== null &&
      selectedTask.space !== undefined
    ) {
      getCustomFields({
        variables: {
          spaceID: selectedTask.space.id,
        },
      })
    }
  }

  function updateInlineMainTaskList(updatedTask, id) {
    let index = tasks.findIndex(ele => ele.id === id)

    setTasks([
      ...tasks.slice(0, index),
      { ...updatedTask },
      ...tasks.slice(index + 1),
    ])

    handleChangeSelectedTask(updatedTask)
    addQueryParams("taskName", updatedTask?.title)
  }

  function updateMainTaskList(updatedTask) {
    handleChangeSelectedTask(updatedTask)
    if (spaceID && spaceID !== "" && updatedTask.isSubtask) {
      return
    }
    addQueryParams("taskName", updatedTask?.title)
    let index = tasks.findIndex(ele => ele.id === updatedTask.id)

    setTasks([
      ...tasks.slice(0, index),
      { ...updatedTask },
      ...tasks.slice(index + 1),
    ])
  }

  function updateMainTask(id) {
    let index = tasks.findIndex(ele => ele.id === id)
    setTasks([...tasks.slice(0, index), ...tasks.slice(index + 1)])
  }

  const handleChangeSelectedTask = subTaskDetail => {
    setSelectedTask(subTaskDetail)
  }

  const handleAddTask = () => {
    console.log("taskInput", taskInput)
    addTask({
      variables: {
        input: taskInput,
      },
    })
  }

  const menu = (
    <Menu
      style={{
        fontSize: "12px",
        borderRadius: "6px",
        width: "150px",
      }}
      onClick={e => {
        let column = defaultCustomFields[parseInt(e.key)]
        handleMenuClick(e.key, column)
      }}
    >
      <Menu.ItemGroup title="Create new field">
        {defaultCustomFields.map((data, index) => {
          return (
            <Menu.Item key={index}>
              <i className={data.icon}></i>
              <span>{data.name}</span>
            </Menu.Item>
          )
        })}
        {/* <Menu.Divider /> */}
      </Menu.ItemGroup>
    </Menu>
  )
  const handleMenuClick = (key, column) => {
    setEditCustomFieldMode(false)
    setCustomField(undefined)
    setShowCustomFieldModal(true)
    setColumn({
      name: column.name,
      icon: column.icon,
      key: key,
    })
  }
  const handleEditCustomField = field => {
    setEditCustomFieldMode(true)
    setCustomField(field)
    setShowCustomFieldModal(true)
    setColumn(undefined)
  }

  const [addCustomField] = useMutation(ADD_CUSTOM_FIELD, {
    onCompleted: data => {
      if (data && data.addCustomField) {
        handleAppendToCustomFields(data.addCustomField)
      }
      if(columnOrder && columnOrder.length){
        handleColumnOrderChange(data.addCustomField)
     }
      setShowCustomFieldModal(false)
    },
    onError: err => {
      console.log("err", err)
      setShowCustomFieldModal(false)
    },
  })

  const [editCustomField] = useMutation(EDIT_CUSTOM_FIELD, {
    update: (cache, { data: { editCustomField } }) => {
      if (editCustomField) {
        const data = cache.readQuery({
          query: GET_CUSTOM_FIELDS_BY_SPACE_ID,
          variables: {
            spaceID,
          },
        })
        let allCustomFields = [data.getCustomFields]
        if (allCustomFields && allCustomFields.length) {
          allCustomFields = [editCustomField, ...allCustomFields]
        } else {
          allCustomFields = [editCustomField]
        }
        cache.writeQuery({
          query: GET_CUSTOM_FIELDS_BY_SPACE_ID,
          variables: {
            spaceID,
          },
          data: {
            getCustomFields: allCustomFields,
          },
        })
        setShowCustomFieldModal(false)
        handleUpdateCustomFieldsArr(editCustomField)
      }
    },
    onError: err => {
      console.log("err---->", err)
      setShowCustomFieldModal(false)
    },
  })

  const onSaveCustomField = input => {
    if (workspaceID) {
      addCustomField({
        variables: {
          input: {
            ...input,
            workspaceID,
          },
        },
      })
    }
  }

  const onEditCustomField = (fieldID, input) => {
    if (fieldID && workspaceID) {
      editCustomField({
        variables: {
          fieldID,
          input: {
            ...input,
            workspaceID,
          },
        },
      })
    }
  }

  const handleCloseTaskFilter = () => {
    setShowTaskFilter(false)
  }

  const searchInputMenu = (
    <Menu
      style={{
        fontSize: "12px",
        borderRadius: "6px",
        width: "150px",
      }}
      onClick={e => {}}
    >
      <Menu.ItemGroup title="Search In">
        <Menu.Item key="1">
          <div className="d-flex justify-content-between align-items-center">
            <div>Task name</div>
            <Switch
              onChange={() => setIsTaskName(!isTaskName)}
              size="small"
              checked={isTaskName}
            />
          </div>
        </Menu.Item>
        <Menu.Item key="2">
          <div className="d-flex justify-content-between align-items-center">
            <div>Task description</div>
            <Switch
              onChange={() => setIsTaskDesc(!isTaskDesc)}
              size="small"
              checked={isTaskDesc}
            />
          </div>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )

  useEffect(() => {
    setFilteredTasks(tasks)
  }, [tasks])

  useEffect(() => {
    if (tasks && tasks.length) {
      let newTasks = tasks.filter(task => {
        let taskTitle = task?.title
          ?.toLowerCase()
          .includes(debouncedSearchText.toLowerCase())
        let taskDesc = task?.description
          ?.toLowerCase()
          .includes(debouncedSearchText.toLowerCase())
        if (isTaskName && isTaskDesc) {
          return taskTitle || taskDesc
        } else if (isTaskName) {
          return taskTitle
        } else {
          return taskDesc
        }
      })
      setFilteredTasks(newTasks)
      setLoading(false)
    }
  }, [debouncedSearchText])

  const handleSearchTask = event => {
    setLoading(true)
    setSearchText(event.target.value)
  }
  if (!authorizedUser) {
    return <NotAuthorizedPage />
  }

  const handleEditGroupCustomField = customField => {
    console.log("customField", customField)
    handleEditCustomField(customField)
  }

  return (
    <React.Fragment>
      <div
        className="page-content mx-2"
        style={
          {
            // overflow: "scroll",
          }
        }
      >
        <MetaTags>
          <title>Tasks</title>
        </MetaTags>
        <Container fluid>
          {spaceID !== "" ? (
            <div>
              {/* <Breadcrumbs title="Spaces" breadcrumbItem="new-list" /> */}

              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-start align-items-center">
                  <button
                    onClick={handleAddTask}
                    className="btn btn-primary btn-sm rounded-3 d-flex justify-content-around align-items-center"
                    size="middle"
                  >
                    <i className="bx bx-plus font-size-14 me-1"></i>
                    Add Task
                  </button>

                  <div className="search-box chat-search-box ms-2">
                    <div className="position-relative">
                      <Input
                        disabled={!isTaskName && !isTaskDesc}
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={handleSearchTask}
                      />
                      <i className="bx bx-search-alt search-icon" />
                      <div className="nobg-btn px-2 py-2 ms-1 cursor-pointer search-dropdown-icon">
                        <Dropdown overlay={searchInputMenu} trigger={["click"]}>
                          <i className="fal fa-chevron-circle-down font-size-16"></i>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end align-items-center">
                  <div
                    className={`me-2 nobg-btn px-2 rounded-3 py-1 cursor-pointer ${
                      taskFilter ? "filter-applied" : ""
                    }`}
                    onClick={() => {
                      setShowTaskFilter(true)
                    }}
                  >
                    <i className="fad fa-filter me-1 font-size-13"></i>
                    <spna className="font-size-13 font-weight-semibold">
                      Filters{" "}
                      {taskFilter ? `(${taskFilter?.filters?.length})` : null}
                    </spna>
                  </div>

                  <div className="me-2 nobg-btn px-2 rounded-3 py-1 cursor-pointer d-none">
                    <i className="far fa-eye me-1 font-size-13"></i>
                    <spna className="font-size-13 font-weight-semibold">
                      Show
                    </spna>
                  </div>

                  <div>
                    <TaskCustomFieldModal
                      showModal={showCustomFieldModal}
                      handleCloseModal={() => setShowCustomFieldModal(false)}
                      column={column}
                      defaultCustomFields={defaultCustomFields}
                      spaceID={spaceID}
                      data={customField}
                      editMode={editCustomFieldMode}
                      onSave={onSaveCustomField}
                      onEdit={onEditCustomField}
                    />
                    <Dropdown
                      overlay={menu}
                      trigger={["click"]}
                      placement="bottomRight"
                    >
                      <button
                        className="btn btn-primary btn-sm rounded-3 d-flex justify-content-around align-items-center"
                        size="middle"
                      >
                        <i className="bx bx-plus font-size-14 text-white"></i>
                      </button>
                    </Dropdown>
                  </div>

                  {/* <AddCustomField
                    workspaceID={workspaceID}
                    spaceID={spaceID}
                    handleAppendToCustomFields={handleAppendToCustomFields}
                  /> */}
                </div>
              </div>
            </div>
          ) : (
            <h4>My Tasks</h4>
          )}

          {/* Right Drawer */}
          <RightDrawer
            openDrawer={openDrawer}
            handleCloseDrawer={handleCloseDrawer}
            selectedTask={selectedTask}
            updateMainTaskList={updateMainTaskList}
            mainTaskID={mainTaskID}
            handleChangeSelectedTask={handleChangeSelectedTask}
            mainCustomFields={customFields}
            spaceID={spaceID}
            workspaceID={workspaceID}
            tenantUsers={users}
            handleEditGroupCustomField={handleEditGroupCustomField}
          />

          {/* Task table */}
          <TaskTable
            loadMoreTasks={loadMoreTasks}
            totalTasksCount={totalTasksCount}
            loading={loading}
            tasks={filteredTasks}
            openDrawer={openDrawer}
            handleOpenDrawer={handleOpenDrawer}
            handleCloseDrawer={handleCloseDrawer}
            updateInlineMainTaskList={updateInlineMainTaskList}
            updateMainTask={updateMainTask}
            updateMainTaskList={updateMainTaskList}
            handleChangeSelectedTask={handleChangeSelectedTask}
            customFields={customFields}
            handleEditCustomField={handleEditCustomField}
            columnOrder={columnOrder}
          />

          {taskFilter ? (
            <div className="font-size-12 mt-1 text-secondary text-center">
              Tasks are hidden. To show all tasks,{" "}
              <span
                onClick={() => {
                  handleDeleteTaskFilter(taskFilter.id)
                }}
                className="clear-filter-btn"
              >
                clear filters.
              </span>
            </div>
          ) : null}

          <TaskFilterModal
            showModal={showTaskFilter}
            handleCloseModal={handleCloseTaskFilter}
            customFields={customFields}
            handleAddTaskFilter={handleAddTaskFilter}
            handleUpdateTaskFilter={handleUpdateTaskFilter}
            taskFilter={taskFilter}
            handleDeleteTaskFilter={handleDeleteTaskFilter}
            users={users}
          />
        </Container>
        {/* <Toaster /> */}
      </div>
    </React.Fragment>
  )
}

export default TaskList