import React, { useState, useEffect } from "react"
import "react-image-gallery/styles/css/image-gallery.css"
import FacebookPreview from "./social/facebookPreview"
import InstagramPreview from "./social/instagramPreview"
import LinkedInPreview from "./social/linkedInPreview"
import TwitterPreview from "./social/twitterPreview"

const PostPreview = ({
  selectedAccounts,
  content,
  posts,
  socialAccounts,
  mediaFiles,
  mobile,
}) => {
  const [accounts, setAccounts] = useState(undefined)
  const [showLinkedIn, setShowLinkedIn] = useState(true)
  const [showTwitter, setShowTwitter] = useState(true)
  const [showFacebook, setShowFacebook] = useState(true)
  const [showInstagram, setShowInstagram] = useState(true)

  useEffect(() => {
    if (selectedAccounts && socialAccounts && selectedAccounts.length) {
      let accs = []
      selectedAccounts.forEach(ele => {
        socialAccounts.forEach(socialAcc => {
          if (socialAcc.id === ele) {
            accs.push(socialAcc)
          }
        })
      })
      console.log("accs", accs)
      setAccounts(accs)
    } else {
      setAccounts(undefined)
    }
  }, [selectedAccounts])

  const handleLinkedin = () => {
    setShowLinkedIn(!showLinkedIn)
  }
  const handleTwitter = () => {
    setShowTwitter(!showTwitter)
  }
  const handleFacebook = () => {
    setShowFacebook(!showFacebook)
  }

  const handleInstagram = () => {
    setShowInstagram(!showInstagram)
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-center align-items-center post-preview-div">
        <div
          className={
            mobile
              ? "post-preview-container mt-2"
              : "post-preview-container mt-2 post-preview-container-desktop"
          }
        >
          <div className="social-accounts d-flex justify-content-center">
            {accounts
              ? accounts.map(acc => {
                  if (acc.accountType === "LinkedIn") {
                    return (
                      <div
                        className={
                          showLinkedIn ? "social-account-bg me-2" : "me-2"
                        }
                        onClick={handleLinkedin}
                      >
                        <img src="https://img.icons8.com/color/38/000000/linkedin.png" />
                      </div>
                    )
                  } else if (acc.accountType === "Twitter") {
                    return (
                      <div
                        className={
                          showTwitter ? "social-account-bg me-2" : "me-2"
                        }
                        onClick={handleTwitter}
                      >
                        <img src="https://img.icons8.com/color/38/000000/twitter--v1.png" />
                      </div>
                    )
                  } else if (acc.accountType === "Facebook") {
                    return (
                      <div
                        className={
                          showTwitter ? "social-account-bg me-2" : "me-2"
                        }
                        onClick={handleFacebook}
                      >
                        <img src="https://img.icons8.com/color/38/000000/facebook--v1.png" />
                      </div>
                    )
                  } else if (acc.accountType === "Instagram") {
                    return (
                      <div
                        className={
                          showInstagram ? "social-account-bg me-2" : "me-2"
                        }
                        onClick={handleInstagram}
                      >
                        <img src="https://img.icons8.com/fluency/48/null/instagram-new.png" />
                      </div>
                    )
                  }
                })
              : null}
          </div>

          {accounts
            ? accounts.map((account, index) => {
                if (account.accountType === "LinkedIn" && showLinkedIn) {
                  return (
                    <LinkedInPreview
                      key={index}
                      mediaFiles={mediaFiles}
                      linkedInAccount={account}
                      content={content}
                      socialPosts={posts ? posts[index] : null}
                    />
                  )
                } else if (account.accountType === "Twitter" && showTwitter) {
                  return (
                    <TwitterPreview
                      key={index}
                      mediaFiles={mediaFiles}
                      twitterAccount={account}
                      content={content}
                      socialPosts={posts ? posts[index] : null}
                    />
                  )
                } else if (account.accountType === "Facebook" && showFacebook) {
                  return (
                    <FacebookPreview
                      key={index}
                      mediaFiles={mediaFiles}
                      facebookAccount={account}
                      content={content}
                      socialPosts={posts ? posts[index] : null}
                    />
                  )
                } else if (
                  account.accountType === "Instagram" &&
                  showInstagram
                ) {
                  return (
                    <InstagramPreview
                      key={index}
                      mediaFiles={mediaFiles}
                      facebookAccount={account}
                      content={content}
                      socialPosts={posts ? posts[index] : null}
                    />
                  )
                }
              })
            : null}
        </div>
      </div>
    </React.Fragment>
  )
}

export default PostPreview
