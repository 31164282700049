import { Divider, Input, Switch } from "antd"

const UserNotificationSettings = () => {
  return (
    <div>
      <div className="my-2">
        <h4 style={{ color: "#333" }}>Task Notifications</h4>
        <div className="font-size-12">Send me In-App notifications for:</div>
        <div>
          <div className="form-check form-check-primary my-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="task-notification-activity-updates"
              //   checked={customchkPrimary}
              onChange={() => {}}
            />

            <label
              className="form-check-label"
              htmlFor="task-notification-activity-updates"
            >
              Activity updates
              <div
                style={{
                  fontWeight: "400",
                  color: "#444",
                }}
                className="font-size-13"
              >
                New tasks assigned to you, @mentions, and completion
                notifications for tasks you're a collaborator on
              </div>
            </label>
          </div>

          <div>
            <div className="form-check form-check-primary my-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="task-notification-mentions-only"
                onChange={() => {}}
              />

              <label
                className="form-check-label"
                htmlFor="task-notification-mentions-only"
              >
                Mentions Only
                <div
                  style={{
                    fontWeight: "400",
                    color: "#444",
                  }}
                  className="font-size-13"
                >
                  New tasks assigned to you, direct messages, and @mentions
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <Divider />

      <div className="my-2">
        <h4 style={{ color: "#333" }}>Email Notifications</h4>

        <div>
          <div className="font-size-12 mb-1 text-black-50">Preferred email</div>
          <Input
            readOnly
            value={"praveen@ontoborn.com"}
            style={{
              width: "fit-content",
            }}
            className="rounded-3"
            size="middle"
          />
        </div>

        <div className="font-size-12 mt-3">
          Send me email notifications for:
        </div>
        <div>
          <div>
            <div className="form-check form-check-primary my-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="email-notification-mentions-only"
                onChange={() => {}}
              />

              <label
                className="form-check-label"
                htmlFor="email-notification-mentions-only"
              >
                Mentions Only
                <div
                  style={{
                    fontWeight: "400",
                    color: "#444",
                  }}
                  className="font-size-13"
                >
                  New tasks assigned to you, direct messages, and @mentions
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserNotificationSettings
