import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { useApolloClient } from "@apollo/client"
import { logoutUser } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

const Logout = props => {
  const dispatch = useDispatch()
  const client = useApolloClient()

  useEffect(() => {
    localStorage.removeItem("concizeUser")
    localStorage.removeItem("authUser")
    dispatch(logoutUser(props.history))
    client.clearStore()
  }, [dispatch])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
