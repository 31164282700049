import React from "react"
import SingleComment from "./singleComment"

const CommentList = ({
  comments,
  handleUpdateComment,
  task,
  updateMainTaskList,
  handleChangeDrawerDatas,
}) => {
  return (
    <div>
      {comments
        ? comments.map((comment, index) => {
            return (
              <div
                key={comment.id}
                style={{
                  backgroundColor: "#f9f8f8",
                }}
                // className={`${index === comments.length - 1 ? "pb-6" : ""}`}
              >
                <SingleComment
                  comment={comment}
                  handleUpdateComment={handleUpdateComment}
                  task={task}
                  updateMainTaskList={updateMainTaskList}
                  handleChangeDrawerDatas={handleChangeDrawerDatas}
                />
              </div>
            )
          })
        : null}
    </div>
  )
}

export default CommentList
