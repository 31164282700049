import React, { useState, useEffect } from "react"
import user from "../../../../assets/images/users/empty-user.png"
import toast from "react-hot-toast"
import { Menu, Dropdown } from "antd"
import ReactHtmlParser from "react-html-parser"
import Linkify from "react-linkify"
import ImageGallery from "react-image-gallery"
import LeftNav from "../leftNav"
import RightNav from "../rightNav"
import uuid from "react-uuid"

const TwitterPreview = ({
  twitterAccount,
  content,
  mediaFiles,
  socialPosts,
}) => {
  const [images, setImages] = useState([])
  const [video, setVideo] = useState(undefined)

  useEffect(() => {
    if (mediaFiles && mediaFiles.length) {
      let newImages = []
      mediaFiles.forEach(media => {
        if (media.type === "video/mp4") {
          setVideo(media.preview)
        } else {
          newImages.push({ original: media.preview, thumbnail: media.preview })
        }
      })
      console.log("mediaFiles", mediaFiles)
      setImages(newImages)
    } else {
      setImages([])
      setVideo(null)
    }
  }, [mediaFiles])
  const menu = account => {
    return (
      <Menu
        style={{
          fontFamily: "sans-serif",
        }}
      >
        <Menu.SubMenu className="mx-2 mt-1" title="Copy Link" key="0">
          {socialPosts?.postPages?.map(pagePost => {
            console.log("pagePost", pagePost)
            return (
              <Menu.Item
                key={uuid()}
                onClick={() => {
                  handleOption("0", pagePost.postURL)
                }}
              >
                <i className="fal fa-link me-1"></i>
                {pagePost.pageName ? pagePost.pageName : "Copy"}
              </Menu.Item>
            )
          })}
        </Menu.SubMenu>

        <Menu.SubMenu className="mx-2 mt-1" title="Open Link" key="1">
          {socialPosts?.postPages?.map(pagePost => {
            return (
              <Menu.Item
                key={uuid()}
                onClick={() => {
                  handleOption("1", pagePost.postURL)
                }}
              >
                <i className="fal fa-external-link me-1"></i>
                {pagePost.pageName ? pagePost.pageName : "Open"}
              </Menu.Item>
            )
          })}
        </Menu.SubMenu>
      </Menu>
    )
  }

  const handleOption = (key, url) => {
    if (url === "" || url === null || url === undefined) {
      toast.error("There's no post url to copy or open", {
        position: "top-right",
      })
      return
    }

    if (key === "0") {
      navigator.clipboard.writeText(url)
      toast.success("The post link was copied to your clipboard.", {
        position: "top-right",
      })
    } else if (key === "1") {
      window.open(url)
    }
  }

  return (
    <div className="post-template mb-3">
      <div className="linkedin-header d-flex justify-content-between align-items-center px-3 py-2">
        <div className="linkedin-header-left d-flex justify-content-center align-items-center">
          <img
            className="rounded-circle linkedin-dp  me-1"
            src={
              twitterAccount.profilePicURL ? twitterAccount.profilePicURL : user
            }
            alt="Header Avatar"
          />
          <div>
            <div className="font-weight-semibold font-size-14">
              {twitterAccount.firstName} {twitterAccount.lastName}
            </div>
            <div
              className="text-secondary font-size-12 position-relative"
              style={{
                width: "90%",
              }}
            >
              @ayush_kumar_singh
            </div>
          </div>
        </div>

        <div>
          <Dropdown
            placement="bottomRight"
            overlay={() => menu(twitterAccount)}
            trigger={["click"]}
            className="linkedin-option text-secondary"
          >
            <i className="fal fa-ellipsis-h font-size-22"></i>
          </Dropdown>
        </div>
      </div>

      <div className="linkedin-desc px-3 py-2">
        <Linkify>{ReactHtmlParser(content)}</Linkify>
      </div>
      <div className="linkedin-img mx-3 mb-2">
        <ImageGallery
          infinite={false}
          showNav={true}
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          showIndex={false}
          items={images}
          renderLeftNav={(onClick, disabled) => {
            return <LeftNav onClick={onClick} disabled={disabled} />
          }}
          renderRightNav={(onClick, disabled) => {
            return <RightNav onClick={onClick} disabled={disabled} />
          }}
        />
        {video ? (
          <video
            style={{
              width: "100%",
            }}
            src={video}
            controls
          ></video>
        ) : null}
      </div>
      <div className="twitter-tweets d-flex align-items-center py-2">
        <div className="me-3">
          <span className="font-weight-semibold">0</span>{" "}
          <span className="text-secondary">Retweets</span>
        </div>
        <div className="me-3">
          <span className="font-weight-semibold">0</span>{" "}
          <span className="text-secondary">Quote Retweets</span>
        </div>
        <div className="me-3">
          <span className="font-weight-semibold">0</span>{" "}
          <span className="text-secondary">Likes</span>
        </div>
      </div>
      <div className="twitter-footer d-flex justify-content-around">
        <div>
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"></path>
            </g>
          </svg>
        </div>
        <div>
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M23.77 15.67c-.292-.293-.767-.293-1.06 0l-2.22 2.22V7.65c0-2.068-1.683-3.75-3.75-3.75h-5.85c-.414 0-.75.336-.75.75s.336.75.75.75h5.85c1.24 0 2.25 1.01 2.25 2.25v10.24l-2.22-2.22c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l3.5 3.5c.145.147.337.22.53.22s.383-.072.53-.22l3.5-3.5c.294-.292.294-.767 0-1.06zm-10.66 3.28H7.26c-1.24 0-2.25-1.01-2.25-2.25V6.46l2.22 2.22c.148.147.34.22.532.22s.384-.073.53-.22c.293-.293.293-.768 0-1.06l-3.5-3.5c-.293-.294-.768-.294-1.06 0l-3.5 3.5c-.294.292-.294.767 0 1.06s.767.293 1.06 0l2.22-2.22V16.7c0 2.068 1.683 3.75 3.75 3.75h5.85c.414 0 .75-.336.75-.75s-.337-.75-.75-.75z"></path>
            </g>
          </svg>
        </div>
        <div>
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"></path>
            </g>
          </svg>
        </div>
        <div>
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M17.53 7.47l-5-5c-.293-.293-.768-.293-1.06 0l-5 5c-.294.293-.294.768 0 1.06s.767.294 1.06 0l3.72-3.72V15c0 .414.336.75.75.75s.75-.336.75-.75V4.81l3.72 3.72c.146.147.338.22.53.22s.384-.072.53-.22c.293-.293.293-.767 0-1.06z"></path>
              <path d="M19.708 21.944H4.292C3.028 21.944 2 20.916 2 19.652V14c0-.414.336-.75.75-.75s.75.336.75.75v5.652c0 .437.355.792.792.792h15.416c.437 0 .792-.355.792-.792V14c0-.414.336-.75.75-.75s.75.336.75.75v5.652c0 1.264-1.028 2.292-2.292 2.292z"></path>
            </g>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default TwitterPreview
