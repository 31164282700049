import React, { useState, useEffect } from "react"
import { DatePicker } from "antd"
import moment from "moment"
import { Select } from "antd"
import { Badge } from "reactstrap"
const { Option } = Select
import {
  GET_TASK,
  GET_ALL_USERS_BY_TENANT_ID,
} from "../../../../../graphql/queries"
import { useQuery, useMutation, useApolloClient } from "@apollo/client"

const AssigneeDropdown = ({
  name,
  data,
  handleAssigneeDeselect,
  handleAssigneeSelect,
}) => {
  const [assignees, setAssignees] = useState(undefined)
  const [users, setUsers] = useState(undefined)

  const {} = useQuery(GET_ALL_USERS_BY_TENANT_ID, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.getUsers) {
        setUsers(data.getUsers)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  useEffect(() => {
    if (data.assignees == null) {
      setAssignees(undefined)
    }
    if (data) {
      if (data.assignees && data.assignees.length) {
        let assigneeList = []
        data.assignees.forEach(ele => {
          if (ele.user) {
            let name = ele.user.fName.concat(" ", ele.user.lName)
            if (ele.user.isDeleted) {
              name += " (Deleted User)"
            }
            let assignee = {
              value: ele.id,
              label: name,
              key: ele.id,
              userID: ele.user.id,
            }
            assigneeList.push(assignee)
          }
        })
        setAssignees(assigneeList)
      }
    }
    console.log("assignees", assignees)
  }, [data])

  return (
    // <div style={{ width: "240px" }}>
    <Select
      dropdownStyle={{ borderRadius: "6px" }}
      className="assigneeHover"
      bordered={false}
      size="middle"
      mode="multiple"
      style={{ width: "200px" }}
      placeholder="No assignee"
      value={assignees ? assignees : undefined}
      // labelInValue={true}
      filterOption={(input, option) => {
        if (option && option.label) {
          return option.label.toLowerCase().includes(input.toLowerCase())
        }
      }}
      onSelect={(_, e) => handleAssigneeSelect(data, e)}
      onDeselect={key => handleAssigneeDeselect(data, key)}
      optionLabelProp="label"
    >
      {users
        ? users.map(ele => {
            if (assignees && assignees.length) {
              console.log(assignees)
              for (let i = 0; i < assignees.length; i++) {
                let a = assignees[i]
                if (a.userID === ele.id) {
                  return null
                }
              }
            }

            let userName = ele.fName.concat(" ", ele.lName)
            if (ele.isDeleted) {
              userName += "\n(Deleted User)"
            }

            return (
              <Option value={ele.id} label={userName} key={ele.id}>
                <div
                  key={ele.id}
                  className="demo-option-label-item d-flex align-items-center"
                >
                  {ele.profileURL ? (
                    <img
                      src={ele.profileURL}
                      alt=""
                      width="36"
                      height="36"
                      className="rounded-circle"
                    />
                  ) : (
                    <div
                      className="avatar-xs"
                      style={{
                        width: "36px",
                        height: "36px ",
                      }}
                    >
                      <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-14">
                        {ele.fName !== undefined ? ele.fName.charAt(0) : "?"}
                      </span>
                    </div>
                  )}

                  <div
                    className="ms-2 font-size-13"
                    style={{
                      width: "calc(100% - 40px)",
                      whiteSpace: "pre",
                    }}
                  >
                    {userName}
                  </div>
                </div>
              </Option>
            )
          })
        : ""}
    </Select>
    // </div>
  )
}

export default AssigneeDropdown
