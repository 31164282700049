import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getFirebaseBackend } from "../../helpers/firebase_helper"

import { Link, useLocation } from "react-router-dom"
import { useHistory } from "react-router-dom"

import { useMutation, useLazyQuery } from "@apollo/client"
// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.png"
import { loginUser } from "../../store/actions"

import { INSERT_USER, REGISTER_SYSTEM_ADMIN } from "../../graphql/mutation"
import { GET_USER_BY_REFRESH_TOKEN } from "../../graphql/queries"
import { ROLE } from "components/helpers/enums"

const Register = props => {
  const dispatch = useDispatch()
  let query = new URLSearchParams(useLocation().search)
  const refreshToken = query.get("refreshToken")

  const [showLoader, setShowLoader] = useState(false)
  const [userValues, setUserValues] = useState({})
  const [showError, setShowError] = useState(false)
  const [loaderText, setLoaderText] = useState("Registering")
  const [userData, setUserData] = useState(undefined)

  const [getUserByRefreshToken] = useLazyQuery(GET_USER_BY_REFRESH_TOKEN, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data && data.getUser) {
        setUserData(data.getUser)
      }
    },
    onError: err => {
      console.log("err", err)
    },
  })

  useEffect(() => {
    if (refreshToken) {
      console.log("refreshToken", refreshToken)
      getUserByRefreshToken({
        variables: {
          refreshToken,
        },
      })
    }
  }, [refreshToken])

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  const [insertUser] = useMutation(INSERT_USER)
  const history = useHistory()

  const updateCache = (cache, { data }) => {
    // Refresh the token to get the one with Custom Claims
    let role = ""
    if (data && data.addUser) {
      role = data.addUser.role
    }
    const fireBaseBackend = getFirebaseBackend()
    /* fireBaseBackend.refreshToken(true).then(data => {
      console.log("Token force refreshed", data)
      history.push("/dashboard")

    });*/

    fireBaseBackend.loginWithCustomToken(data.addUser.token).then(data => {
      if (role === ROLE.systemAdmin || role === ROLE.globalAdmin) {
        history.push("/workspace")
      } else {
        history.push("/profile")
      }
    })
    /*console.log(data)
    let obj = JSON.parse(localStorage.getItem("authUser"))
    if (obj !== null && obj !== "null" && loading === false) {
      obj.stsTokenManager.accessToken = data.addUser.token
      localStorage.setItem("authUser", JSON.stringify(obj))
    }
    else{
      //TODO: Revert back -> Delete the user in firebase
    }*/
  }
  const [registerSystemAdmin] = useMutation(REGISTER_SYSTEM_ADMIN, {
    update: updateCache,
  })

  useEffect(() => {
    let obj = JSON.parse(localStorage.getItem("authUser"))
    if (obj !== null && obj !== "null" && loading === false) {
      let user = {
        tenantName: userValues.tenantName,
        tenantID: "",
        fName: userData ? userData.fName : "",
        lName: userData ? userData.lName : "",
        email: userData ? userData.email : userValues.email,
        mobile: "",
        role: userData ? userData.role : "SYSTEM_ADMIN",
        refreshToken: refreshToken !== null ? refreshToken : "",
      }

      localStorage.setItem("concizeUser", JSON.stringify(user))
      if (user.email) {
        registerSystemAdmin({
          variables: {
            input: user,
          },
        })
      }
    }
  }, [loading])

  useEffect(() => {
    if (registrationError) {
      setShowError(true)
      setShowLoader(false)
    } else {
      setShowError(false)
      setShowLoader(true)
    }
    if (user && !registrationError) {
      setLoaderText("Logging In")
      setShowLoader(true)
      dispatch(loginUser(userValues, props.history))
    }
    return () => {
      setShowLoader(false)
    }
  }, [user, registrationError])
  const registerAPI = values => {
    let user = {
      fName: "",
      lName: "",
      email: userData ? userData.email : userValues.email,
      mobile: "",
      role: "SUPERADMIN",
      tenantName: userValues.tenantName,
    }
    registerSystemAdmin({
      variables: {
        input: values,
      },
    }).then(data => {
      dispatch(loginUser(data, props.history))
    })
  }

  // handleValidSubmit
  const handleValidSubmit = values => {
    //registerAPI(values)
    let v = {
      email: userData ? userData.email : values.email,
      tenantName: values.tenantName,
      password: values.password,
    }
    setUserValues(v)
    dispatch(registerUser(v))
  }

  useEffect(() => {
    dispatch(apiError(""))
    setShowLoader(false)
  }, [])

  const handleAlertClose = () => {
    setShowError(false)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Concize</title>
      </MetaTags>
      <div className="pt-5">
        <Container>
          <div
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "99",
              textAlign: "center",
            }}
          >
            <Card hidden={!showLoader}>
              <CardBody>
                <p>{loaderText}</p>
                <Spinner color="primary" />
              </CardBody>
            </Card>
          </div>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card
                style={{ opacity: showLoader ? "0.5" : "1" }}
                className="overflow-hidden"
              >
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Free Register</h5>
                        <p>Get your free Concize account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div>
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        setShowError(false)
                        setShowLoader(true)
                        handleValidSubmit(v)
                      }}
                    >
                      <Alert
                        color="danger"
                        toggle={handleAlertClose}
                        isOpen={showError}
                      >
                        {registrationError ? registrationError : ""}
                      </Alert>

                      <div className="mb-3">
                        {userData ? (
                          <span>
                            <label>Email</label>
                            <input
                              disabled
                              type="email"
                              id="email"
                              defaultValue={userData.email}
                              className="form-control"
                            />
                          </span>
                        ) : (
                          <AvField
                            id="email"
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        )}
                      </div>

                      {refreshToken === null ? (
                        <div className="mb-3">
                          <AvField
                            name="tenantName"
                            label="Organisation Name"
                            type="text"
                            required
                            placeholder="Enter Organisation Name"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Concize
                          <a
                            href="https://concize.co/terms-and-conditions/"
                            target="_blank"
                            className="text-primary"
                          >
                            Terms of Use
                          </a>
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-2 d-flex justify-content-center">
                <p className="me-2"> Already have an account ?</p>
                <Link to="/login" className="font-weight-medium text-primary">
                  Login
                </Link>
              </div>
              <p className="text-center">© 2022 Concize</p>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
