import React from "react"
import { Tabs } from "antd"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import "./user-settings.css"
import UserNotificationSettings from "./UserSettings/UserNotificationSettings"
import { useState } from "react"

const { TabPane } = Tabs

const UserSettings = ({ showUserSettings, handleClose }) => {
  const [activeKey, setActiveKey] = useState("2")

  const handleUserSettingsTab = key => {
    setActiveKey(key)
  }

  return (
    <Modal size="lg" isOpen={showUserSettings} scrollable={true}>
      <ModalHeader tag="h4" toggle={handleClose}>
        My Settings
      </ModalHeader>
      <ModalBody className="mx-2">
        <Tabs
          onChange={handleUserSettingsTab}
          activeKey={activeKey}
          className="user-settings-tabs"
          defaultActiveKey="1"
          size="small"
        >
          <TabPane tab="Notifications" key="2">
            <UserNotificationSettings />
          </TabPane>
        </Tabs>
      </ModalBody>
      {activeKey !== "2" ? <ModalFooter>Footer</ModalFooter> : null}
    </Modal>
  )
}

export default UserSettings
