import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/user.png"
import { ROLE } from "components/helpers/enums"
import UserSettings from "./UserSettings"
import { GlobalContext } from "contexts/index"

const ProfileMenu = props => {
  let role
  const obj = JSON.parse(localStorage.getItem("concizeUser"))
  const { profilePicSrcCtx } = useContext(GlobalContext)
  const [userName, setUserName] = useState(undefined)
  const [menu, setMenu] = useState(false)
  const [showUserSettings, setShowUserSettings] = useState(false)

  useEffect(() => {
    if (obj && obj !== null && obj.fName !== userName) {
      setUserName(obj.fName)
    }
  }, [obj])

  const handleUserSettings = () => {
    setShowUserSettings(true)
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profilePicSrcCtx ? profilePicSrcCtx : user1}
            alt="Header Avatar"
          />
          <span className="d-xl-inline-block ms-2 me-1">{userName}</span>
          <i className="mdi mdi-chevron-down d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item py-2">
            <i className="bx bx-user font-size-18 align-middle me-2" />
            <span>{props.t("Profile")}</span>
          </Link>
          <div
            onClick={handleUserSettings}
            className="dropdown-item py-2 cursor-pointer d-none"
          >
            <i className="bx bx-user-cog font-size-14 align-middle me-2" />
            <span>{props.t("My Settings")}</span>
          </div>
          {role === ROLE.systemAdmin || role === ROLE.spaceAdmin ? (
            <React.Fragment>
              <Link to="/settings" className="dropdown-item py-2">
                <i className="bx bx-cog font-size-18 align-middle me-2" />
                <span>{props.t("Settings")}</span>
              </Link>
            </React.Fragment>
          ) : (
            ""
          )}

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item py-2">
            <i className="bx bx-log-out font-size-18 align-middle me-2 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <UserSettings
        showUserSettings={showUserSettings}
        handleClose={() => setShowUserSettings(false)}
      />
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
